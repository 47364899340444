<template>
  <a-config-provider :locale="locale" :theme="theme" v-if="!loading">
    <!-- 页头 -->
    <navroot :class="{ 'phone-mode': phoneMode }" v-if="!hideNav"></navroot>
    <div id="content" :class="{ 'phone-mode': phoneMode, 'hideNav': hideNav }">
      <router-view />
    </div>
    <phoneBar v-if="phoneMode & !hideNav"></phoneBar>
  </a-config-provider>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN } from "./config/url";
import { MAIN_STORE_ID } from '@/config/constant'
import { mapState } from "vuex";

//汉化
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

import headPath from "@/filter/headPath";
import navroot from "@/component/public/nav/nav.vue";
import phoneBar from "@/component/public/phone-bar/phone-bar.vue";

export default {
  name: "App",
  data() {
    return {
      urlToken: "",
      token: "",

      storeBooks: [],
      theme: {
        token: {
          colorPrimary: '#AB3982', // 默认颜色
          colorText: '#333333', // 默认字体颜色
        }
      },
      loading: true,
      locale: zhCN,
      phoneMode: false,
      hideNav: false,

      isSingle: false,
    };
  },
  computed: {
    ...mapState({
      currentStore: state => state.currentStore,
    })
  },
  watch: {
    // 监听店铺信息变化
    currentStore: {
      handler(newVal) {
        if (newVal) {
          this.setPageConfig();
        }
      },
      immediate: true
    }
  },

  components: {
    navroot,
    phoneBar,
  },
  mounted() {
    //获取当前路由
    const href = location.href;
    if (href.indexOf('bookReader') > -1 || href.indexOf('login') > -1) {
      this.hideNav = true;
    }
    if (href.indexOf('/s/') > -1) {
      this.isSingle = true;
    }

    const { name } = this.$route;
    if (name === 'bookReader') this.hideNav = true;

    this.urlToken = this.getURLQuery("token");
    this.judgePhone();
    this.judegeMiniProgram();
  },
  methods: {
    //判断手机端
    judgePhone() {
      let w_width = window.innerWidth;
      let phoneMode = w_width < 700;
      if (this.phoneMode == phoneMode) return;

      this.phoneMode = phoneMode;
      this.$store.commit("phoneMode", this.phoneMode);
    },
    //检查是否在小程序中
    async judegeMiniProgram() {
      let isMiniProgram = await this.$api.passport.checkInMiniProgram();
      this.isMiniProgram = isMiniProgram;

      if (isMiniProgram && this.urlToken) {
        const ret = await this.$api.passport.verifyToken(this.urlToken);

        if (ret.status === 1) {
          this.userGuid = ret.userGuid;
          this.token = ret.token;

          window.localStorage.setItem(LESSONPLAY_TOKEN, this.urlToken);
          this.loadFunction();

          return;
        }
      }
      this.judgeToken();
    },
    //判断token
    async judgeToken() {
      const urlToken = this.getURLQuery("token");
      const token = urlToken || window.localStorage.getItem('LESSONPLAY_TOKEN');

      if (!token) {
        const herfurl = location.href.split("?")[0];
        location.href = `${PASSPORT_CLIENT_SERVER}/auth/checkTouristLogin?redirectURI=${herfurl}`;
      }
      else if (token !== 'tourist') {
        const ret = await this.$api.passport.verifyToken(token);
        if (ret.status === 1) {
          this.userGuid = ret.userGuid;
          this.token = ret.token;
          window.localStorage.setItem(`LESSONPLAY_TOKEN`, ret.token);
        } else {
          window.localStorage.removeItem('LESSONPLAY_TOKEN');
        }
      }

      this.loadFunction();
    },
    //删除URL中的token
    deleteURLToken() {
      setTimeout(() => {
        const searchParams = new URLSearchParams(location.search)
        searchParams.delete('token')
        const newURL = searchParams.toString() ? `${location.pathname}?${searchParams.toString()}` : location.pathname;
        history.replaceState({}, '', newURL);
      }, 100);
    },
    //获取URL中的值
    getURLQuery(query) {
      const search = new URLSearchParams(location.search);
      const value = search.get(query);
      return value;
    },
    //加载方法
    async loadFunction() {
      this.deleteURLToken();

      if (!this.userGuid) this.storeData();
      else this.getUserInfo();
    },
    //获取用户信息
    async getUserInfo() {
      const userGuid = this.userGuid;

      const user = await this.$api.unifydata.getStudent({ userGuid }).then((res) => res.data);
      if (user) {
        user.Name = this.desensitize(user.Name);
        user.Phone = this.desensitize(user.Phone);
        user.Email = this.desensitize(user.Email);
        user.NickName = user.NickName || user.Name;
        user.token = this.token;
        user.HeadPhotoPath = headPath(user.HeadPhotoPath);
      }
      this.user = user;
      this.$store.commit("userInfo", user);

      if (this.hideNav) return this.loading = false;

      this.storeData();
    },

    //加载商店信息
    async storeData() {
      if (this.isSingle) return this.loading = false;
      if (!MAIN_STORE_ID) return

      const currentStore = await this.$api.bookManage.storeFindOne({ pk_store_id: MAIN_STORE_ID }).then((res) => res.data || []);
      this.$store.commit("setCurrentStore", currentStore);
      const storeUrl = window.location.origin;
      this.$store.commit("setStoreUrl", storeUrl);

      //获取书店图书
      const storeBooks = await this.$api.bookManage.storeBookFind(MAIN_STORE_ID).then((res) => res.data || []);
      this.storeBooks = storeBooks;
      this.$store.commit("setStoreBooks", storeBooks);

      if (!this.user) {
        this.loading = false;
      }
      else this.loadUser();
    },

    setPageConfig() {
      const { name, page_config } = this.currentStore;
      if (!page_config) return;
      const { theme_color, logo, background_image } = page_config

      if (theme_color) {
        this.theme = {
          token: {
            colorPrimary: theme_color,
            text_color: '#333333'
          }
        };
        document.documentElement.style.setProperty('--primary-color', theme_color);
      }

      document.title = name;

      if (logo) {
        const favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = logo
        document.head.appendChild(favicon)
      }

      if (background_image) {
        setTimeout(() => {
          const contentEl = document.querySelector('#content');
          if (contentEl) {
            contentEl.style.backgroundImage = `url(${background_image})`;
          }
        }, 500);
      }
    },

    //加载用户相关信息
    async loadUser() {
      const user = this.user;
      const userGuid = user.PK_UserGuid;
      const bookUser = await this.$api.bookManage.bookUserNew({
        fk_user_id: userGuid,
        account: user.Name,
        name: user.NickName || user.Name,
        avatar: user.HeadPhotoPath,
      }).then((res) => res.data || null);

      //获取我的购买订单
      const orderList = await this.$api.payorder.getOrderList(userGuid).then((res) => res.data || []);
      this.storeBooks.forEach(book => {
        const order = orderList.find((oitem) => oitem.system_id === book.system_id);
        if (order) book.isPaid = order.isPaid;

        if (bookUser?.join_class) {
          const { join_class } = bookUser;
          const joinClass = join_class.find((item) => item.fk_publishing_id === book.fk_publishing_id);
          if (joinClass) {
            book.fk_book_class_id = joinClass.fk_book_class_id;
          }
        }
      })
      this.$store.commit("setStoreBooks", this.storeBooks);

      this.loading = false;
    },

    //进行脱敏处理
    desensitize(value) {
      if (!value) return value;
      const phone = value.match(/1[3456789]\d{9}/)?.[0];
      if (phone) {
        const newPhone = phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
        value = value.replace(phone, newPhone);
      }
      const email = value.match(/[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+/)?.[0];
      if (email) {
        const newEmail = email.replace(/(\w{2})\w+(@\w+\.\w+)/, '$1****$2');
        value = value.replace(email, newEmail);
      }

      return value;
    }
  }
};
</script>

<style></style>
