import { createRouter, createWebHistory } from 'vue-router';
import home from '@/component/home/home.vue';
import allCourse from '@/component/all-course/all-course.vue';
import courseInfo from '@/component/course-info/course-info.vue';
import mycourse from '@/component/mycourse/mycourse.vue';
import payment from '@/component/course-info/payment/payment.vue';
import payorder from '@/component/course-info/payment/pay-order/pay-order.vue';
import payfinish from '@/component/course-info/payment/pay-finish/pay-finish.vue';
import personal from '@/component/personal/personal.vue';
import bookReader from '@/component/bookReader.vue';
import login from '@/component/login/login.vue';
import SingleSite from '@/component/SingleSite.vue';

const childrenRoutes = [
  { path: '', name: 'empty', redirect: 'home' },
  { path: 'login', name: 'login', component: login },
  { path: 'home', name: 'home', component: home },
  { path: 'all-course', name: 'allCourse', component: allCourse },
  { path: 'mycourse', name: 'mycourse', component: mycourse },
  { path: 'course-info/:id', name: 'course', component: courseInfo },
  { path: 'payment/:id', name: 'payment', component: payment },
  { path: 'payorder/:id', name: 'payorder', component: payorder },
  { path: 'payfinish', name: 'payfinish', component: payfinish },
  { path: 'payfinish/:id', name: 'payfinish', component: payfinish },
  { path: 'personal', name: 'personal', component: personal },
  { path: 'bookReader/:id', name: 'bookReader', component: bookReader }
]

const routes = [
  ...childrenRoutes.map(route => ({
    ...route,
    path: route.path ? `/${route.path}` : '',
  })), {
    path: '/s/:shortName', name: 'SingleSite', component: SingleSite,
    children: childrenRoutes.map(child => ({
      ...child,
      name: child.name ? `site-${child.name}` : undefined,
      redirect: child.redirect ? { name: `site-${child.redirect}` } : undefined
    }))
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
export default router
