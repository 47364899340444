<template>
  <!-- 个人中心 -->
  <div class="personal-box no-image">
    <div class="person-head">
      <div class="personal">
        <div class="avatar" v-if="userInfo">
          <i class="avatar-icon" :style="{ backgroundImage: 'url(' + userInfo.HeadPhotoPath + ')' }"></i>
          <p>{{ userInfo.NickName }}</p>
        </div>
      </div>
      <div class="person-menu">
        <div class="menu">
          <span :class="{ selected: currentType == item.type }" v-for="item in menuList"
            @click="currentType = item.type">{{ item.name }}</span>
        </div>
      </div>
    </div>
    <div class="person-content" v-if="userInfo">
      <userInfo v-if="currentType == 'user-info'"></userInfo>
      <buyOrder v-if="currentType == 'buy-order'"></buyOrder>
      <portraitsInfo v-if="currentType == 'portraits-info'"></portraitsInfo>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import userInfo from "@/component/personal/user-info/user-info.vue";
import buyOrder from "@/component/personal/buy-order/buy-order.vue";
import achievement_list from "@/component/personal/achievement_list/achievement_list.vue";
import portraitsInfo from '@/component/personal/portraits-info/portraits-info.vue';

export default {
  components: {
    foot,
    navroot,
    userInfo,
    buyOrder,
    achievement_list,
    portraitsInfo
  },
  data() {
    return {
      userInfo: null,
      menuList: [
        { name: '个人信息', type: 'user-info' },
        { name: '已购订单', type: 'buy-order' },
        // { name: '徽章/证书', type: 'achieve' },
        { name: '阅读数据', type: 'portraits-info' }
      ],
      currentType: "user-info",
    }
  },
  async mounted() {
    this.$store.commit('showReturn', true);

    const { userInfo } = this.$store.state;
    if (!userInfo) {
      this.$router.push({ path: `login` });
      return
    }

    this.userInfo = userInfo;
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "./personal.scss";
</style>