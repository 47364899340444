<template>
  <div class="video-player">
    <video controls controlslist="nodownload" ref="video">
      <source :src="item.video">
    </video>
    <div class="setting" v-if="item.subtitleOriginal && item.subtitleOriginal.length">
      显示字幕： <input type="checkbox" v-model="showSubtitle" @change="onChange(showSubtitle)">
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showSubtitle: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$refs.video && this.item.subtitleOriginal) {
        this.setSubtitle()
      }
    })
  },
  methods: {
    setSubtitle() {
      const video = this.$refs.video
      const track = video.addTextTrack('captions', '字幕', 'zh-cn')
      this.item.subtitleOriginal.forEach(subtitle => {
        const cue = new VTTCue(
          this.timeStringToSeconds(subtitle.start),
          this.timeStringToSeconds(subtitle.end),
          subtitle.text
        )
        track.addCue(cue)
      })
      if(video.textTracks.length > 0) {
        video.textTracks[0].mode = 'showing'
      }
    },
    onChange() {
      const video = this.$refs.video
      if(this.showSubtitle) {
        video.textTracks[0].mode = 'showing'
      }
      else {
        video.textTracks[0].mode = 'hidden'
      }
    },
    timeStringToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(parseFloat)
      return hours * 3600 + minutes * 60 + seconds
    }
  }
}
</script>
<style scoped>
video
{
  width: 100%;
}

.setting
{
  font-size: 14px;
  display: flex;
  align-items: center;
}

input[type="checkbox"]
{
  width: 20px;
  height: 20px;
  background-color: #ccc;
}

input[type="checkbox"]:checked
{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>