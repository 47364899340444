<template>
    <router-view v-if="!loading" />
</template>

<script>
import router from '@/router'
import store from "@/store";

export default {
    data() {
        return {
            currentStore: {},
            loading: true,
            store: store.state.storeUrl,
            user: store.state.userInfo,
        }
    },
    mounted() {
        const { params } = router.currentRoute.value;
        const { shortName } = params;
        if (shortName) {
            this.storeData(shortName);
        }
    },
    methods: {
        //加载商店信息
        async storeData(shortName) {
            const currentStore = await this.$api.bookManage.storeFindOne({ short_name: shortName }).then((res) => res.data || []);
            if (!currentStore) return this.$message.error("找不到书店信息");
            this.$store.commit("setCurrentStore", currentStore);
            this.currentStore = currentStore;

            const storeUrl = `${window.location.origin}/s/${shortName}`;
            this.$store.commit("setStoreUrl", storeUrl);

            //获取书店图书
            const storeBooks = await this.$api.bookManage.storeBookFind(currentStore.pk_store_id).then((res) => res.data || []);
            this.storeBooks = storeBooks;
            this.$store.commit("setStoreBooks", storeBooks);
;
            if (!this.user) {
                this.loading = false;
            }
            else this.loadUser();
        },
        //加载用户相关信息
        async loadUser() {
            const user = this.user;
            const userGuid = user.PK_UserGuid;
            const bookUser = await this.$api.bookManage.bookUserNew({
                fk_user_id: userGuid,
                account: user.Name,
                name: user.NickName || user.Name,
                avatar: user.HeadPhotoPath,
            }).then((res) => res.data || null);

            //获取我的购买订单
            const orderList = await this.$api.payorder.getOrderList(userGuid).then((res) => res.data || []);
            this.storeBooks.forEach(book => {
                const order = orderList.find((oitem) => oitem.system_id === book.system_id);
                if (order) book.isPaid = order.isPaid;

                if (bookUser?.join_class) {
                    const { join_class } = bookUser;
                    const joinClass = join_class.find((item) => item.fk_publishing_id === book.fk_publishing_id);
                    if (joinClass) {
                        book.fk_book_class_id = joinClass.fk_book_class_id;
                    }
                }
            })
            this.$store.commit("setStoreBooks", this.storeBooks);

            this.loading = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.full-iframe {
    width: 100%;
    height: 100%;
    background: #f7f3f2;
}
</style>