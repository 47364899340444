import {
  createStore
} from 'vuex'

const store = createStore({
  state() {
    return {
      userInfo: null,
      storeBooks: [],
      levelInfo: null,
      allAchievementList: [],
      showReturn: 'home',
      phoneMode: false,

      currentStore: null,
      storeBooks: [],
      storeUrl: '',
    }
  },
  mutations: {
    userInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    storeBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    },
    levelInfo(state, levelInfo) {
      state.levelInfo = levelInfo;
    },
    allAchievementList(state, allAchievementList) {
      state.allAchievementList = allAchievementList;
    },
    showReturn(state, showReturn) {
      state.showReturn = showReturn;
    },
    phoneMode(state, phoneMode) {
      state.phoneMode = phoneMode;
    },
    setCurrentStore(state, currentStore) {
      state.currentStore = currentStore;
    },
    setStoreBooks(state, storeBooks) {
      state.storeBooks = storeBooks;
    },
    setStoreUrl(state, storeUrl) {
      state.storeUrl = storeUrl;
    }
  },
  actions: {},
  modules: {}
})

export default store