<template>
    <div class="new-popup-box fadeIn" @click="closebox">
        <div class="hanlder-box">
            <svg class="icon-svg popup-close" @click="closebox">
                <use xlink:href="#icon-guanbi" />
            </svg>
        </div>
        <div class="box-content class-item fadeIn" @click.stop>
            <template v-if="!joinClass">
                <div class="join-code">
                    <a-input class="join-input" v-model:value="invitation_code" placeholder="请输入邀请码" :maxlength="6"
                        v-focus size="large" @keyup.enter="searchClass" />
                    <a-button class="join-button" type="primary" @click="searchClass" :loading="isJoining"
                        size="large">加入班级</a-button>
                </div>
            </template>
            <template v-else>
                <i class="item-cover" :style="{ backgroundImage: 'url(' + joinClass.store_book_cover + ')' }"></i>
                <div class="item-info">
                    <div class="item-title">{{ joinClass.store_book_name }}</div>
                    <div class="item-time">创建：{{ joinClass.create_time }}</div>
                </div>
                <div class="item-bottom">
                    <div class="item-int">
                        <svg class="icon-svg int-icon">
                            <use xlink:href="#icon-student-count" />
                        </svg>
                        <span>{{ joinClass.reader_count || 0 }}人</span>
                    </div>
                    <a-button class="enter-button" type="primary" @click="joining" :loading="isJoining">确定加入</a-button>
                </div>
            </template>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import store from "@/store";
import api from "@/services";

const props = defineProps({
    joinBook: Object,
})
const emit = defineEmits(['closebox', 'joinSuccess'])

const isJoining = ref(false);
const userGuid = ref('');
const invitation_code = ref('')
const isSearching = ref(false);
const joinClass = ref(null);

onMounted(() => {
    const { userInfo } = store.state;
    userGuid.value = userInfo.PK_UserGuid;
});

//搜索班级
async function searchClass() {
    if (isSearching.value) return;
    isSearching.value = true;
    let code = invitation_code.value;
    if (code) code = code.trim();
    else return message.warning('请输入邀请码');

    const data = await api.bookManage.storeClassFindByCode(code).then(res => res.data);
    isSearching.value = false;
    if (!data) return message.warning('邀请码不正确');
    if (props.joinBook.pk_store_book_id !== data.fk_store_book_id) return message.warning('班级与该教材不匹配');

    data.invitation_code = code;
    joinClass.value = data;
}

async function joining() {
    if (isJoining.value) return;
    isJoining.value = true;
    const res = await api.bookManage.bookUserJoinClass({
        fk_user_id: userGuid.value,
        invitation_code: invitation_code.value
    })
    isJoining.value = false;
    if (res.code != 1) return message.warning(res.message);

    message.success('加入班级成功');
    emit('joinSuccess', joinClass.value);
};

function closebox() {
    emit('closebox');
}

</script>

<style lang="scss" scoped>
.new-popup-box {
    .class-item {
        width: 340px;
        height: auto;
        padding: 25px 20px;
        border-radius: 10px;
        margin: 10px;
        transition: all .3s ease;
        cursor: pointer;
        background-color: #565656cc;
        box-shadow: $box-shadow;

        .join-code {

            .join-button {
                margin-top: 20px;
                width: 100%;
            }
        }

        .item-cover {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 10px;
            height: 300px;
            width: 100%;
            display: block;
        }

        .item-info {
            padding: 20px 0;

            .item-title {
                font-size: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item-int {
                display: flex;
                align-items: center;
                font-size: 15px;

                .int-icon {
                    margin-right: 5px;
                    font-size: 16px;
                }
            }

            .enter-button {
                width: 100px;
            }
        }
    }
}
</style>