<template>
  <div class="course-info" v-if="!loading">
    <div class="course-box fadeIn" v-if="book">
      <div class="course-top">
        <i class="i-icon banner-cover" :style="{ backgroundImage: 'url(' + book.extension_info?.banner + ')' }"
          v-if="book.extension_info?.banner"></i>
        <i class="i-icon course-cover" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }"
          v-else-if="book.extension_info?.cover"></i>
        <div class="info-box">
          <div class="info-title">{{ book.name }}</div>
          <div class="book-other-info">
            <div class="book-subTitle" v-if="book.chief_editor_name">
              <svg class="svg-icon">
                <use xlink:href="#icon-person" />
              </svg>
              <span>{{ book.chief_editor_name }}</span>
            </div>
            <div class="book-subTitle" v-if="book.isbn">
              <svg class="svg-icon">
                <use xlink:href="#icon-tiaoxingma" />
              </svg>
              <span>{{ book.isbn }}</span>
            </div>
            <div class="book-subTitle" v-if="book.publisherName">
              <svg class="svg-icon">
                <use xlink:href="#icon-read" />
              </svg>
              <span>{{ book.publisherName }}</span>
            </div>
          </div>

          <div class="courseprice" v-if="book.is_unique_book">内测版本</div>
          <div class="courseprice" v-else="book.is_unique_book">
            <span :class="{ 'original-price': limitedTimeDiscount }">￥{{ book.price }}</span>
            <span v-if="limitedTimeDiscount" class="discount-price">￥{{ limitedTimeDiscount.discountPrice }}</span>
          </div>
          <div v-if="limitedTimeDiscount" class="discount-info">
            <span class="discount-rate">限时{{ limitedTimeDiscount.discount }}折优惠</span>
            <span class="countdown">{{ limitedTimeDiscount.remainTime }}</span>
          </div>

          <a-button class="buy-button" type="primary" size="large" @click="login()" v-if="!userInfo">请先登录</a-button>
          <template v-else-if="!isPaid">
            <a-button class="buy-button" type="primary" size="large" @click="buy()">立即{{
              book?.is_unique_book ? '激活' : '购买' }}</a-button>
            <a-button class="buy-button try-read" size="large" @click="routerStudy()"
              v-if="book.trial_chapter?.length > 0">试读</a-button>
          </template>
          <a-button class="buy-button" type="primary" size="large" @click="routerStudy()" v-else>进入学习</a-button>
        </div>
      </div>

      <div class="course-menu-box">
        <div class="course-menu">
          <div class="menu-title" :class="{ actived: activeMenu === item }" v-for="item in menuList"
            @click="activeMenu = item">{{ item }}</div>
        </div>
        <template v-if="activeMenu === '简介'">
          <div class="course-content ck-editor" v-html="book.extension_info?.introduce"
            v-if="book.extension_info?.introduce">
          </div>
          <div class="empty-box" v-else>
            <img :src="empty_image" alt />
            <div>暂无简介</div>
          </div>
        </template>
        <template v-if="activeMenu === '目录'">
          <div class="catalog" v-if="book.catalog">
            <div class="catalog-item" v-for="int in book.catalog">
              <div class="catalog-part" v-if="int.part">{{ int.part }}</div>
              <div class="catalog-title">{{ int.title }}</div>
              <div class="nav-item" :class="'h' + oitem.level" v-for="oitem in int.children">{{
                oitem.content }}
              </div>
            </div>
          </div>
          <div class="empty-box" v-else>
            <img :src="empty_image" alt />
            <div>暂无目录</div>
          </div>
        </template>

        <template v-if="activeMenu === '资源'">
          <div class="resources" v-if="resourcesTags">
            <div class="resources-item" v-for="item in resourcesTags">
              <span class="resources-title">{{ item.title }}：</span>
              <span class="resources-count">{{ item.count }}</span>
            </div>
          </div>
          <div class="empty-box" v-else>
            <img :src="empty_image" alt />
            <div>暂无资源</div>
          </div>
        </template>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import navroot from "@/component/public/nav/nav.vue";
import foot from "@/component/public/foot/foot.vue";
import { EMOJI_LIST } from "@/config/emoji.js";
import store from "@/store";
import moment from 'moment';

export default {
  name: 'course-info',
  components: {
    navroot,
    foot,
  },
  data() {
    return {
      storeBookId: '',

      platformUrl: '',
      empty_image: 'https://img.lessonplan.cn/IMG/yuanxi/images/empty.png',
      isPaid: false,
      loading: true,

      book: null,
      userInfo: null,
      vipInfo: null,
      currentStore: null,

      allStoreBooks: [],
      otherBooks: [],

      activeMenu: "简介",
      menuList: ['简介', '目录', '资源'],
      resourcesTags: [
        { key: 'image', title: '图片', count: 0 },
        { key: 'video', title: '视频', count: 0 },
        { key: 'audio', title: '音频', count: 0 },
        { key: 'iframe', title: '网页', count: 0 },
        { key: 'mi', title: '交互式视频', count: 0 },
        { key: 'cs', title: '课件', count: 0 },
        { key: 'hw', title: '作业', count: 0 },
        { key: 'co', title: '主题讨论', count: 0 },
        { key: 'kc', title: '在线测验', count: 0 },
        { key: 'tr', title: '协同白板', count: 0 },
        { key: 'bb', title: '元宇宙仿真', count: 0 },
        { key: 'intex', title: '严肃游戏', count: 0 },
        { key: 'interact', title: '互动', count: 0 },
        { key: 'attachment', title: '附件', count: 0 },
      ],

      menuGrade: [
        { name: '1级', value: 'h0' },
        { name: '2级', value: 'h1' },
        // { name: '3级', value: 'h2' },
        // { name: '4级', value: 'h3' },
        // { name: '5级', value: 'h4' },
        // { name: '6级', value: 'h5' },
        // { name: '7级', value: 'h6' },
      ],

      idx: 0,
      num: 7,
      showcouser: true,
      alshow: false,
      phoneMode: false,

      limitedTimeDiscount: null,
      interDiscountTime: null,

      storeUrl: store.state.storeUrl,
      payLimitMsg: '',
    }
  },
  mounted() {
    const content = document.getElementById('content');
    if (content) content.scrollTo({ top: 0, behavior: 'smooth' });
    this.$store.commit('showReturn', true);

    this.storeBookId = this.$route.params.id;
    const { userInfo, storeBooks, phoneMode, currentStore } = this.$store.state;
    this.phoneMode = phoneMode;
    this.userInfo = userInfo;
    this.currentStore = currentStore;

    this.allStoreBooks = JSON.parse(JSON.stringify(storeBooks || []));
    this.otherBooks = storeBooks.filter(item => item.pk_store_book_id != this.storeBookId && item.is_recommend);
    this.book = storeBooks.find(item => item.pk_store_book_id == this.storeBookId);
    if (!this.book) return this.$message.error('未找到教材信息');

    this.loading = false;
    if (this.userInfo) this.findOrder();

    this.getCatalog();
    this.setDiscount();
  },
  methods: {
    //登录
    login() {
      this.$router.push({ path: '../login' });
    },
    //购买
    buy() {
      if (this.payLimitMsg) return this.$message.warning(this.payLimitMsg);
      this.$router.push({ path: `../payment/${this.storeBookId}` });
    },
    //学习
    routerStudy() {
      const { pk_store_book_id, system_id, single_url, storeUrl, isOtherStore } = this.book;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }

      if (isOtherStore) {
        url = `${storeUrl}${url}`
      }
      window.open(url)
    },
    //跳转
    chooseCourse(item) {
      window.location.href = `/course-info/${item.pk_store_book_id}`;
    },
    //判断是否有购买订单
    async findOrder() {
      const { system_id } = this.book;
      const res = await this.$api.payorder.findPayOrder({ system_id, fk_userGuid: this.userInfo.PK_UserGuid });
      if (res.statusCode != 200) return this.$message.error(res.error);

      if (res.data?.isPaid) this.isPaid = true;
      else {
        const judgePayRes = await this.$api.payorder.judgeCanBuy(this.storeBookId);
        console.log(judgePayRes)
        if (judgePayRes && !judgePayRes.canPay) {
          this.payLimitMsg = judgePayRes.message;
        }
      }
    },
    //提取目录
    async getCatalog() {
      const { fk_publishing_id, repository, single_url } = this.book;
      if (single_url) return;

      const fileContent = await this.$api.bookManage.getFileContent(`${repository.url}/${fk_publishing_id}`).then(res => res.data);
      if (!fileContent) return;
      const { list } = fileContent;

      list.forEach(int => {
        int.tags = [];

        if (int.TextbookContent) {
          const textbookContent = JSON.parse(int.TextbookContent);
          const content = textbookContent.content;

          //使用正则表达式提取标题标签
          const regex = /<h[1-6][^>]*>(.*?)<\/h[1-6]>/g;
          const matches = content.match(regex);
          let newMatches = [];
          if (matches) {
            matches.forEach((item) => {
              EMOJI_LIST.forEach((emoji) => {
                item = item.replace(new RegExp(`:${emoji.shortcode}:`, "g"), `${emoji.ico}`);
              })

              const levels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
              for (const level of levels) {
                if (item.includes(`<${level}`)) {
                  item = `${this.removeHTMLTags(level, item)}`;
                  newMatches.push(item);
                }
              }
            })
          }
          int.showTextbookContent = newMatches.join("");

          this.countResource(textbookContent);
        }
      })
      this.resourcesTags = this.resourcesTags.filter(item => item.count > 0);

      this.setMindmap(list);
      this.countResource(fileContent);
    },
    //统计资源数据
    async countResource(textbookContent) {
      const tags = this.resourcesTags;
      const content = textbookContent.content
      const appConfigs = textbookContent.appConfigs;
      const interactionConfigs = textbookContent.interactionConfigs

      const parser = new DOMParser()
      const doc = parser.parseFromString(content, 'text/html')
      const nodes = doc.querySelectorAll('.image-view, .gallery-view, .video-view, .audio-view, .iframe-view, .app-view[data-app-type], .interaction-view, .attachment-view')

      for (const node of nodes) {
        if (node.classList.contains('image-view')) {
          const img = node.querySelector('img')
          if (img.dataset.inoperable) continue
          tags[0].count++
        }
        else if (node.classList.contains('gallery-view')) {
          const imgs = node.querySelectorAll('img')
          for (let i = 0; i < imgs.length; i++) {
            tags[0].count++
          }
        }
        else if (node.classList.contains('video-view')) {
          const video = node.querySelector('video')
          if (this.isInvalidSrc(video.src)) continue
          tags[1].count++
        }
        else if (node.classList.contains('audio-view')) {
          const audio = node.querySelector('audio')
          if (this.isInvalidSrc(audio.src)) continue
          tags[2].count++
        }
        else if (node.classList.contains('iframe-view')) {
          const iframe = node.querySelector('iframe')
          if (this.isInvalidSrc(iframe.src)) continue
          tags[3].count++
        }
        else if (node.classList.contains('app-view')) {
          const nodeId = node.dataset.id
          const config = appConfigs[nodeId]

          if (config) {
            if (config.type === 'mi') tags[4].count++
            if (config.type === 'cs') tags[5].count++
            if (config.type === 'hw') tags[6].count++
            if (config.type === 'co') tags[7].count++
            if (config.type === 'kc') tags[8].count++
            if (config.type === 'tr') tags[9].count++
            if (config.type === 'bb') tags[10].count++
            if (config.type === 'intex') tags[11].count++
          }
        }
        else if (node.classList.contains('interaction-view')) {
          const nodeId = node.dataset.id
          const config = interactionConfigs[nodeId]
          if (config) {
            tags[12].count++
          }
        }
        else if (node.classList.contains('attachment-view')) {
          tags[13].count++
        }
      }
    },
    isInvalidSrc(src) {
      return !src || src.includes(location.origin + location.pathname)
    },


    //去除html
    removeHTMLTags(level, html) {
      const tmp = document.createElement("div");
      tmp.innerHTML = html;
      let id = $(html).attr('data-id')
      if (!id) return '';

      id = id.replaceAll('\\"', '');
      let text = tmp.textContent || tmp.innerText || "";
      text = text.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/gu, '');
      return `<${level} nav-data-id="${id}">${text}</${level}>`
    },
    setMindmap(list) {
      const catalog = [];
      list.forEach(int => {
        let outline = {
          id: int.PK_InductionGuid,
          title: int.InductionName,
          part: int.Part || '',
          children: []
        }

        let children = [];
        if (int.showTextbookContent) {
          let content = int.showTextbookContent;
          let dom = document.createElement('div');
          dom.innerHTML = content;
          //按照content标签顺序获取
          let showGrade = [];
          this.menuGrade.forEach(grade => {
            showGrade.push(grade.value);
          })
          if (showGrade.length > 0) {
            let showGradeString = showGrade.join(',');
            let headers = dom.querySelectorAll(showGradeString);
            headers.forEach(h => {
              let level = h.tagName.toLowerCase().replace('h', '');
              let content = h.innerText;
              children.push({
                level,
                content,
                id: h.getAttribute('nav-data-id'),
              })
            })
          }
        }
        outline.children = children;
        catalog.push(outline);
      })
      this.book.catalog = catalog;
    },


    //滚动条移动
    otherListScroll(type) {
      let scroll = $('.course-box').scrollLeft();
      if (type == 'left') $('.course-box').animate({ scrollLeft: scroll - 800 }, 300)
      else if (type == 'right') $('.course-box').animate({ scrollLeft: scroll + 800 }, 300)
    },

    async setDiscount() {
      const { pk_store_book_id, limited_time_discount, price } = this.book;
      if (!limited_time_discount) return;
      const { discount } = limited_time_discount

      const remainSeconds = await this.$api.bookManage.storeBookDiscountTime(pk_store_book_id).then(res => res.data);
      if (remainSeconds > 0) {
        this.limitedTimeDiscount = {
          discount,
          discountPrice: (price * (discount / 10)).toFixed(2),
          remainSeconds,
          remainTime: `00:00:00`
        }
        this.limitedTimeDiscount.remainTime = this.setRemainTime();

        this.interDiscountTime = setInterval(() => {
          this.limitedTimeDiscount.remainSeconds--;
          this.limitedTimeDiscount.remainTime = this.setRemainTime();
        }, 1000)
      }
    },
    setRemainTime() {
      const { remainSeconds } = this.limitedTimeDiscount;
      if (remainSeconds <= 0) {
        this.limitedTimeDiscount = null;
        clearInterval(this.interDiscountTime);
        return '00:00:00';
      }

      const duration = moment.duration(remainSeconds, 'seconds');
      const minutes = duration.minutes().toString().padStart(2, '0');
      const seconds = duration.seconds().toString().padStart(2, '0');
      const totalHours = (duration.days() * 24 + duration.hours()).toString().padStart(2, '0');

      return `${totalHours}:${minutes}:${seconds}`;
    },
  },
  beforeUnmount() {
    if (this.interDiscountTime) clearInterval(this.interDiscountTime);
  }
}
</script>

<style scoped lang="scss">
@import "./course-info.scss";
</style>