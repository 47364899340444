<template>
  <div class="nav-box">
    <div class="logo-box" @click="returnHome()">
      <img class="logo-icon" :src="currentStore?.page_config?.nav_logo" alt />
    </div>
    <div class="menu-box" v-if="menuList.length > 1">
      <div class="menu-item" :class="{ selected: item.selected }" v-for="item of menuList" v-show="item.show"
        :key="item.name" @click="chooseMenu(item)">{{ item.name }}</div>
    </div>
    <div class="user-box">
      <div class="user-handler">
        <a-tooltip title="帮助">
          <svg class="icon-svg" @click="showHelp = true">
            <use xlink:href="#icon-help"></use>
          </svg>
        </a-tooltip>
      </div>
      <a-button class="login-button" type="primary" @click="openUrl('editor')">出版物编辑</a-button>
      <a-button class="login-button" type="primary" @click="routerPath('login')" v-if="!userInfo">读者登录</a-button>
      <div class="logined" v-if="userInfo">
        <a-dropdown :trigger="['click']">
          <i class="avatar" :style="{ backgroundImage: 'url(' + userInfo?.HeadPhotoPath + ')' }"></i>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="routerMenu(`personal`)">
                <span>个人中心</span>
              </a-menu-item>
              <a-menu-item @click="logout()">
                <span>退出登录</span>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>

    <phoneGuide @closeBox="showGuide = false" v-if="showGuide"></phoneGuide>
    <help v-if="showHelp" @close="showHelp = false"></help>
  </div>
</template>

<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN } from '@/config/url';
import phoneGuide from './phone-guide/phone-guide.vue';
import help from './help.vue';
import { mapState } from "vuex";

export default {
  name: "navroot",
  components: {
    phoneGuide,
    help
  },
  data() {
    return {
      userInfo: null,
      menuList: [{ name: "首页", url: "home", selected: false, show: true }],
      showReturn: false,
      showGuide: false,
      showHelp: false,
      phoneMode: false,

      isMaxSize: true,
      ipcRenderer: null,
      shortName: '',

      desktopUrl: 'https://electronserve.lessonplan.cn/yuanxi/win64/元习 Setup 1.0.1.exe',
      device: ''
    }
  },
  computed: {
    ...mapState({
      currentStore: state => state.currentStore,
      storeUrl: state => state.storeUrl,
    })
  },
  async mounted() {
    const { params } = this.$route;
    this.singleName = params?.shortName || '';

    const { userInfo, device, phoneMode } = this.$store.state;
    if (userInfo) {
      this.userInfo = userInfo;
      this.menuList.push({ name: "我的数字出版物", url: "mycourse", selected: false, show: true });
    }
    this.device = device;
    this.phoneMode = phoneMode;

    this.$store.subscribe((res) => {
      if (!res) return;
      let { type, payload } = res;
      if (type == "showReturn") this.showReturn = payload;
    })

    this.judgeMenu();
    this.routerChange();
    this.judgeFirstOpen();
  },
  methods: {
    //监听路由变化
    routerChange() {
      this.$router.afterEach((to, from) => {
        this.judgeMenu();
      })
    },
    //判断当前菜单
    judgeMenu() {
      this.$nextTick(() => {
        this.menuList.forEach(menu => {
          if (menu.url != '/' && location.href.indexOf(menu.url) > -1) menu.selected = true;
          else menu.selected = false;

          menu.show = true;
        })
      })
    },
    //选择菜单
    chooseMenu(item) {
      this.menuList.forEach(menu => { menu.selected = false });
      item.selected = true;
      this.routerPath(item.url);
    },
    //退出
    logout() {
      this.$nzconfirm('确定要退出登录吗?', () => {
        let token = window.localStorage.getItem(LESSONPLAY_TOKEN);
        window.localStorage.removeItem(LESSONPLAY_TOKEN);

        location.href = `${PASSPORT_CLIENT_SERVER}/auth/logout?token=${token}&redirectURI=${this.storeUrl}&isTourist=true`;
      })
    },
    //跳转至个人中心
    routerMenu(url) {
      this.routerPath(url);
      this.menuList.forEach(menu => {
        menu.selected = false
      })
    },
    //返回首页
    returnHome() {
      this.routerPath('home');
    },
    //重置滚动条
    resetScroll() {
      this.showReturn = false;
      $("body").animate({ scrollTop: 0 }, 300);
    },
    //下载桌面应用
    downApp() {
      this.$Modal.confirm({
        okText: '确定',
        cancelText: '取消',
        title: `确定要下载桌面应用吗`,
        content: '下载后，请在浏览器下载中心，查看下载进度',
        onOk: () => {
          this.openUrl(this.desktopUrl);
        }
      })
    },
    //打开新网页
    openUrl(url) {
      if (url === 'editor') url = this.currentStore.page_config?.editor_url || 'https://www.lessonplan.cn';
      window.open(url);
    },
    //判断用户是否首次打开帮助
    async judgeFirstOpen() {
      if (!this.userInfo || this.phoneMode) return;

      const { PK_UserGuid } = this.userInfo;
      const bookuser = await this.$api.bookManage.bookUserFindOne(PK_UserGuid).then(res => res.data);
      if (!bookuser) return;

      const { pk_book_user_id, record } = bookuser;
      if (!record || !record.first_open_help) {
        this.showHelp = true;
        let newRecord = {};
        if (record) newRecord = { ...record, first_open_help: true };
        else newRecord = { first_open_help: true };

        this.$api.bookManage.bookUserUpdate({
          pk_book_user_id,
          record: newRecord
        });
      }
    },
    routerPath(url) {
      url = this.singleName ? `/s/${this.singleName}/${url}` : `/${url}`;
      this.$router.push(url);
      this.resetScroll();
    }
  }
}
</script>

<style scoped lang="scss">
@import "./nav.scss";
</style>