<template>
  <!-- 个人信息 -->
  <div class="user-info-box fadeIn" v-if="userInfo">
    <!-- 基本信息 -->
    <div class="area-box basic">
      <div class="info-title">基本信息</div>
      <form action>
        <div class="form-group">
          <label>昵称</label>
          <a-input v-model:value="userInfo.NickName" placeholder="请输入昵称" :maxlength="20" />
        </div>
        <div class="form-group">
          <label>手机号</label>
          <a-input v-model:value="userInfo.Phone" placeholder="请输入手机号" :maxlength="11" />
        </div>
        <div class="form-group">
          <label>电子邮箱</label>
          <a-input v-model:value="userInfo.Email" placeholder="请输入电子邮箱" :maxlength="40" />
        </div>
        <div class="form-group">
          <label class="item">性别</label>
          <a-radio-group class="radio-box" v-model:value="userInfo.Sex">
            <a-radio value="男">男</a-radio>
            <a-radio value="女">女</a-radio>
            <a-radio value="保密">保密</a-radio>
          </a-radio-group>
        </div>
        <div class="form-group">
          <label>学校</label>
          <a-input v-model:value="userInfo.School" placeholder="请输入学校" :maxlength="50" />
        </div>
        <div class="form-group">
          <label>学号</label>
          <a-input v-model:value="userInfo.StudentID" placeholder="请输入学号" :maxlength="50" />
        </div>
      </form>
      <a-button :loading="isEditInfo" :disabled="isEditProblem || isEditPW" @click="editUserInfo()">修改信息</a-button>
    </div>
    <!-- 修改密保 -->
    <div class="area-box secret">
      <div class="info-title">修改密保</div>
      <form action>
        <div class="form-group">
          <label>登录账户</label>
          <a-input v-model:value="userInfo.Name" disabled />
        </div>
        <div class="form-group">
          <label>密保问题</label>
          <a-select class="ant-select-box" v-model:value="myProblem.FK_ProblemGuid" placeholder="请选择问题">
            <a-select-option :value="item.PK_ProblemGuid" v-for="item in problemList">
              {{ item.Content }}</a-select-option>
          </a-select>
        </div>
        <div class="form-group">
          <label>密保答案</label>
          <a-input v-model:value="myProblem.Answer" placeholder="请输入答案" :maxlength="100" />
        </div>
      </form>
      <a-button :loading="isEditProblem" :disabled="isEditInfo || isEditPW" @click="editSecurity()">修改密保</a-button>
    </div>
    <!-- 修改密码 -->
    <div class="area-box fixpwd">
      <div class="info-title">修改密码</div>
      <form action>
        <div class="form-group">
          <label>原始密码</label>
          <a-input v-model:value="password.oldpw" placeholder="请输入原始密码" :maxlength="20" />
          <span>修改密码前请输入原始密码，确保是本人操作</span>
        </div>
        <div class="form-group resetpw">
          <label>重设密码</label>
          <a-input type="password" v-model:value="password.newpw" placeholder="请输入新密码" :maxlength="20" />
          <span>密码要求至少包含字母，符号或数字中的两项且长度超过6位，建议经常修改密码，以保证账号更加安全</span>
        </div>
        <div class="form-group">
          <label>确认密码</label>
          <a-input type="password" v-model:value="password.confirmpw" placeholder="请再次输入新密码" :maxlength="20" />
          <span>为避免你忘记新密码，请再次输入你即将修改</span>
        </div>
      </form>
      <a-button :loading="isEditPW" :disabled="isEditInfo || isEditProblem" @click="editPassword()">修改密码</a-button>
    </div>

    <a-button class="logout" type="primary" size="large" @click="logout()" v-if="phoneMode">退出登录</a-button>

  </div>
</template>
<script>
import { PASSPORT_CLIENT_SERVER, LESSONPLAY_TOKEN } from '@/config/url';
import store from "@/store";

export default {
  components: {},
  data() {
    return {
      userGuid: '',
      activeCode: '',
      userInfo: {},
      problemList: [],
      myProblem: {
        FK_ProblemGuid: null,
        Answer: null
      },
      password: {
        oldpw: '',
        newpw: '',
        confirmpw: ''
      },

      isEditInfo: false,
      isEditProblem: false,
      isEditPW: false,
      isVerifying: false,

      phoneMode: false,
      storeUrl: store.state.storeUrl
    }
  },
  async mounted() {
    let { phoneMode, userInfo } = this.$store.state;
    this.userInfo = userInfo;
    this.userGuid = this.userInfo.PK_UserGuid;
    this.phoneMode = phoneMode;

    this.getProblemInfo();
  },
  methods: {
    //获取密保信息
    async getProblemInfo() {
      this.problemList = await this.$api.accounts.getProblemList().then(res => res.data || []);
      this.myProblem = await this.$api.accounts.getMyProblem(this.userGuid).then(res => res.data ? res.data[0] : null);
    },
    //修改用户信息
    async editUserInfo() {
      if (this.isEditInfo) return;
      let msg = '';
      let userInfo = this.userInfo;
      for (const key in userInfo) {
        if (userInfo[key] && typeof (userInfo[key]) === 'string') {
          userInfo[key] = userInfo[key].trim();
        }
      }
      if (!userInfo.NickName) msg = '请输入昵称';
      else if (!userInfo.Phone || userInfo.Phone.length != 11) msg = '请输入正确的手机号';
      if (msg) return this.$message.warning(msg);
      this.isEditInfo = true;
      let newData = {
        PK_UserGuid: userInfo.PK_UserGuid,
        NickName: userInfo.NickName,
        Phone: userInfo.Phone,
        Email: userInfo.Email,
        Sex: userInfo.Sex,
        School: userInfo.School,
        StudentID: userInfo.StudentID
      };
      for (const key in newData) {
        if (newData[key] && typeof (newData[key]) === 'string') {
          if (newData[key].indexOf('*') != -1) msg = '请不要输入*号';
        }
      }

      let res = await this.$api.unifydata.updateStudent(newData);
      this.isEditInfo = false;
      if (res.statusCode != 200) return this.$message.error(res.message);

      this.$message.success('基本信息修改成功');
      this.userInfo = res.data;
      this.$store.commit('userInfo', this.userInfo);
    },
    //修改密保
    async editSecurity() {
      if (this.isEditProblem) return;

      this.$nzconfirm("您确认要将当前密保问题以及答案进行更改么？", async () => {
        let myProblem = this.myProblem;
        if (myProblem.Answer && typeof (myProblem.Answer) === 'string') myProblem.Answer = myProblem.Answer.trim();

        let msg = '';
        if (!myProblem.FK_ProblemGuid) msg = '请先选择问题';
        if (!myProblem.Answer) msg = '请输入答案';

        if (msg) return this.$message.warning(msg);

        let newData = {
          Answer: myProblem.Answer,
          PK_ProblemGuid: myProblem.FK_ProblemGuid,
          PK_UserGuid: this.userGuid
        };
        let res = await this.$api.accounts.editSecurity(newData);
        this.isEditProblem = false;
        if (res.status != 1) return this.$message.error(res.message);
        this.$message.success('密保修改成功');
      })
    },
    //修改密码
    async editPassword() {
      if (this.isEditPW) return;
      let password = this.password;

      let msg = '';
      for (const key in password) {
        if (password[key] && typeof (password[key]) === 'string') password[key] = password[key].trim();
      }
      if (!password.oldpw) msg = '请输入原始密码';
      else if (!password.newpw) msg = '请输入新密码';
      else if (password.newpw.length < 6) msg = '请至少输入6位密码';
      else if (password.oldpw == password.newpw) msg = '新旧密码不能相同';
      else if (!password.confirmpw) msg = '请再次输入新密码';
      else if (password.newpw != password.confirmpw) msg = '两次输入的密码不一致';

      if (msg) return this.$message.warning(msg);

      this.isEditPW = true;
      //验证旧密码
      let checkRes = await this.$api.accounts.checkPassword(this.userGuid, password.oldpw).then(res => res.data);
      if (checkRes.length == 0) {
        this.isEditPW = false;
        return this.$message.warning('原始密码不正确');
      }

      let res = await this.$api.accounts.putPassword(this.userGuid, password.newpw);

      this.isEditPW = false;
      if (res.status != 1) return this.$message.error(res.message);
      this.password = {
        oldpw: '',
        newpw: '',
        confirmpw: ''
      }
      this.$message.success('密码重置成功，请您在下次登录的时候使用新密码进行登录');
    },

    //退出
    logout() {
      this.$nzconfirm('确定要退出登录吗?', () => {
        let token = window.localStorage.getItem(LESSONPLAY_TOKEN);
        window.localStorage.removeItem(LESSONPLAY_TOKEN);
        location.href = `${PASSPORT_CLIENT_SERVER}/auth/logout?token=${token}&redirectURI=${this.storeUrl}&isTourist=true`;
      })
    },
  }
};
</script>

<style scoped lang="scss">
@import "./user-info.scss";
</style>