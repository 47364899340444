<template>
    <div class="wrong-book">
        <div class="question wrong-question-item" v-for="(item, index) of wrongQuestion" :key="index">
            <Choice v-if="item.Content.type == '单项选择题' || item.Content.type == '多项选择题' || item.Content.type == '判断题'"
                :item2="item.Content" :stuAnswer="item.stuAnswer">
            </Choice>
            <FillInTheBlank v-else-if="item.Content.type == '填空题'" :item2="item.Content" :stuAnswer="item.stuAnswer">
            </FillInTheBlank>
            <ShortAnswer v-else-if="item.Content.type == '问答题'" :item2="item.Content" :stuAnswer="item.stuAnswer">
            </ShortAnswer>
            <Translate v-else-if="item.Content.type == '翻译题'" :item2="item.Content" :stuAnswer="item.stuAnswer">
            </Translate>
            <Write v-else-if="item.Content.type == '写作题'" :item2="item.Content" :stuAnswer="item.stuAnswer">
            </Write>
            <AudioVisual v-else-if="item.Content.type == '视听题'" :item2="item.Content" :stuAnswer="item.stuAnswer">
            </AudioVisual>
            <!-- <Comprehensive v-else-if="item.Content.type == '综合题'" :item2="item.Content"
                :stuAnswer="item.stuAnswer">
            </Comprehensive> -->
            <Result :question="item.Content" />
            <div class="point" v-if="item.Point && item.Point.length">
                知识点： <span v-for="(item, index) of item.Point" :key="index">
                    {{ item }} </span>
            </div>
        </div>

    </div>
</template>

<script>
import Result from './question/Result.vue'
import Choice from './question/Choice.vue'
import FillInTheBlank from './question/FillInTheBlank.vue'
import ShortAnswer from './question/ShortAnswer.vue'
import Translate from './question/Translate.vue'
import Write from './question/Write.vue'
import AudioVisual from './question/AudioVisual.vue'
import 'katex/dist/katex.min.css'
// import Comprehensive from './question/Comprehensive.vue'
export default {
    components: {
        Result,
        Choice,
        FillInTheBlank,
        ShortAnswer,
        Translate,
        Write,
        AudioVisual,
        // Comprehensive,
    },
    props: {
        wrongQuestion: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            selectIndex: 0,
        }
    },
    computed: {
        selectQuestion() {
            return this.wrongQuestion[this.selectIndex]
        },
        canPrev() {
            return this.selectIndex > 0
        },
        canNext() {
            return this.selectIndex < this.wrongQuestion.length - 1
        },
    },
    async mounted() {
        for (const item of this.wrongQuestion) {
            if (typeof item.Content === 'string') {
                item.Content = JSON.parse(item.Content)
            }
            if (typeof item.Point === 'string') {
                item.Point = JSON.parse(item.Point)
            }
        }
    },
    methods: {
    },
};
</script>

<style lang="scss" scoped>
.point {
    span {
        text-decoration: underline;
        margin-right: 6px;
    }
}

.tools {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: right;
    font-size: 24px;

    .disable {
        color: #ccc;
        cursor: not-allowed !important;
    }

    span {
        margin-left: 20px;
        cursor: pointer;
    }
}

.ant-input-disabled {
    color: #333 !important;
}

.q-index {
    font-weight: bold;
    color: #fff;
    margin-bottom: 10px;
}

.wrong-book {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.wrong-question-item {
    width: calc(50% - 10px);
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid rgba(108, 108, 130, 0.6196078431);
    background-image: linear-gradient(to bottom, var(--primary-color) #ffffff);
    margin-right: 5px;
    margin-left: 5px;
}

::-webkit-scrollbar {
    width: 7px;
    border-radius: 4px;
    background-color: #00000000;
}

::-webkit-scrollbar-thumb {
    background-color: #cccccc99;
}

.phone-mode {
    .wrong-book{
        .wrong-question-item {
            width:100%
        }
    }
}
</style>