<template>
  <div class="home-box fadeIn">
    <template v-if="normalRender">
      <div class="title-box">
        <div class="main-title">{{ currentStore.name }}</div>
        <a-input-search class="input-search" v-model:value="keyword" placeholder="请输入教材名称搜索" @search="onSearch"  />
      </div>
      <div class="course">
        <div class="catalog-box" v-if="catalogList.length > 1">
          <div class="catalog">
            <div class="catalog-item" :class="{ selected: catActive == i, noicon: !catalog.icon }"
              v-for="(catalog, i) of catalogList" @click="chooseCatalog(i)">
              <i class="catalog-icon" :style="{ backgroundImage: 'url(' + catalog.icon + ')' }" v-if="catalog.icon"></i>
              <span class="catalog-title">{{ catalog.name }}</span>
            </div>
          </div>
          <div class="catalog-page catalog-left" @click="catalogScroll('left')">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="catalog-page catalog-right" @click="catalogScroll('right')">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>

        <div class="course-box">
          <div class="course-item-box" :class="{ hide: i > 9 }" v-for="(book, i) of showList"
            @click="routerCourse(book)">
            <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + '!260x390)' }">
              <div class="course-top">
                <div class="has-buy" v-if="book.isPaid">已购买</div>
                <a-tooltip title="分享" v-if="userInfo">
                  <div class="svg-box" @click.stop="shareBook = book">
                    <svg class="icon-svg" :style="{ fontSize: '16px' }">
                      <use xlink:href="#icon-share"></use>
                    </svg>
                  </div>
                </a-tooltip>
                <a-tooltip title="阅读数据" v-if="!book.single_url && book.isPaid && !phoneMode">
                  <div class="svg-box" @click.stop="portraitsBook = book">
                    <svg class="icon-svg">
                      <use xlink:href="#icon-statistics"></use>
                    </svg>
                  </div>
                </a-tooltip>
                <a-tooltip title="详情">
                  <div class="svg-box info" @click="routerInfo(book)" @click.stop>
                    <svg class="icon-svg">
                      <use xlink:href="#icon-info"></use>
                    </svg>
                  </div>
                </a-tooltip>
              </div>
              <div class="course-bottom">
                <div class="book-tag" v-if="book.hasMetaverse">元宇宙</div>
                <div class="join-class-box" v-if="!book.single_url && book.isPaid && !phoneMode">
                  <div class="join-class" v-if="!book.fk_book_class_id">
                    <a-tooltip title="加入教材班">
                      <div class="svg-box" @click.stop="joinBook = book">
                        <svg class="icon-svg">
                          <use xlink:href="#icon-join-class"></use>
                        </svg>
                      </div>
                    </a-tooltip>
                  </div>
                  <div class="join-class joined" v-else>
                    <a-tooltip title="已加入教材班">
                      <div class="svg-box">
                        <svg class="icon-svg">
                          <use xlink:href="#icon-student-count"></use>
                        </svg>
                      </div>
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div class="course-info">
              <div class="course-name">{{ book.name }}</div>
              <div class="course-sub-name" v-if="book.chief_editor_name">{{ book.chief_editor_name }}</div>
              <div class="course-price">￥{{ book.price }}</div>
            </div>
          </div>
          <emptyBox warning="Sorry,暂无此类教材" v-if="showList.length == 0"></emptyBox>
        </div>

        <div class="course-bottom" v-if="storeBooks.length >= pageSize">
          <div class="pagination pagination-left" @click="changePage(page - 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="pagination pagination-center" @click="routerPath('all-course')"
            v-if="catalogList[catActive]?.id == ''">
            全部
          </div>

          <div class="pagination pagination-right" @click="changePage(page + 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>

      </div>

      <div class="home-info" v-if="info">
        <div class="info-text ck-editor" v-html="info"></div>
        <img class="info-img" src="@/assets/images/home/info.png" />
      </div>
      <foot v-if="!phoneMode"></foot>
    </template>

    <JoinClass :joinBook="joinBook" @closebox="joinBook = null" @joinSuccess="joinSuccess" v-if="joinBook">
    </JoinClass>

    <!-- 学生画像 -->
    <div class="new-popup-box portraits-box fadeIn" v-if="portraitsBook">
      <div class="hanlder-box">
        <svg class="icon-svg popup-close" @click="portraitsBook = null">
          <use xlink:href="#icon-guanbi" />
        </svg>
      </div>
      <div class="box-content">
        <PortraitsContent :currentBook="portraitsBook"></PortraitsContent>
      </div>
    </div>

    <ShareBook :book="shareBook" @close="shareBook = null" v-if="shareBook"></ShareBook>
  </div>
</template>

<script>
import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import emptyBox from "@/component/public/empty-box/empty-box.vue";
import JoinClass from "@/component/mycourse/JoinClass.vue";
import PortraitsContent from "@/component/personal/portraits-info/portraits-content.vue";
import ShareBook from "@/component/public/ShareBook.vue"
import store from "@/store";

export default {
  name: "home",
  components: {
    navroot,
    foot,
    emptyBox,
    JoinClass,
    PortraitsContent,
    ShareBook
  },
  data() {
    return {
      catalogList: [],
      catActive: 0,

      allStoreBooks: [],
      storeBooks: [],
      showList: [],

      page: 1,
      pageSize: 10,
      maxPage: 1,
      needScroll: false,
      listenerWindow: true,

      rdList: [],
      rdLast: null,
      rdSlideshow: 3000,
      rdActive: 0,
      rdInterval: null,

      pageTemplate: null,
      navContent: null,
      newOpenBook: null,
      keyword: "",

      userInfo: null,
      vipPastdue: true,

      loadding: true,
      phoneMode: false,
      updateDgUser: false,
      normalRender: true,

      joinBook: null,
      portraitsBook: null,
      shareBook: null,

      currentStore: store.state.currentStore,
      storeUrl: store.state.storeUrl,
      info: "",
    };
  },
  mounted() {
    //监听store
    this.$store.subscribe((res) => {
      if (!res) return;

      let { type, payload } = res;
      if (type == "phoneMode") this.phoneMode = payload;
    })

    const info = this.currentStore?.page_config?.introduction || "";
    if (info) {
      this.info = info.replace(/\n/g, '<br>')
    }

    this.setPageTemplate();
    this.judgeCardMove();
  },
  methods: {
    //设置pageTemplate
    setPageTemplate() {
      const {
        userInfo,
        storeBooks,
        phoneMode,
        currentStore
      } = this.$store.state;

      this.userInfo = userInfo;
      this.allStoreBooks = JSON.parse(JSON.stringify(storeBooks));
      this.phoneMode = phoneMode;

      const typeGroups = currentStore.typeGroups;
      let catalogList = [{ name: "全部", id: "" }]
      if (typeGroups.length > 0) catalogList = [...catalogList, ...typeGroups[0].list];
      this.catalogList = catalogList;

      const rdList = storeBooks;
      this.rdLast = rdList[rdList.length - 1];
      this.rdList = rdList;

      this.catActive = 0;
      this.chooseCatalog(this.catActive);

      //监听窗口变化
      this.isPhoneMode();
      window.addEventListener("resize", this.isPhoneMode);
    },
    //是否是手机端
    isPhoneMode() {
      if (!this.listenerWindow) return;
      this.listenerWindow = false;
      setTimeout(() => { this.listenerWindow = true }, 3000);

      const { phoneMode } = this.$store.state;
      this.phoneMode = phoneMode;

      // //去除轮训
      // if (this.rdInterval) clearInterval(this.rdInterval);
      // //自动轮播
      // setTimeout(() => {
      //   if (!phoneMode) {
      //     if (this.rdList.length > 1) {
      //       this.rdAutoMove();
      //     }
      //   } else this.pageSize = 6;
      // }, 100);
    },

    ////////////////////////////////推荐轮播
    //选择推荐
    rdChoose(i, type) {
      let rdActive = this.rdActive;
      if ((i == rdActive && type != "dot") || this.phoneMode) {
        return this.routerInfo(this.rdList[i]);
      }

      let dom = this.$refs.recommended;
      if (!dom) return;
      dom.style.transitionDuration = `1s`;

      let item_dom = this.$refs[`rd_${i}`];
      if (item_dom > 0) item_dom[0].style.transition = `all .7s ease`;

      let old_item_dom = this.$refs[`rd_${rdActive}`];
      if (old_item_dom > 0) old_item_dom[0].style.transition = `all .7s ease`;

      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `.3s`;
        if (item_dom.length > 0) item_dom[0].style.transition = `all .3s ease`;
        if (old_item_dom > 0) old_item_dom[0].style.transition = `all .3s ease`;
      }, 1000);

      this.rdMove(i);
    },
    //选择占位
    rdHolder(i) {
      let rdActive = this.rdActive;
      if (i == rdActive) return;

      this.rdMove(i);
    },
    //rd鼠标移入移出
    rdMouse(type) {
      if (this.phoneMode) return;
      if (type == "enter") {
        window.addEventListener("mousewheel", this.rdMousewheel);
        $("body").css("overflow-y", "hidden");
        $("body").css("padding-right", "10px");
      } else {
        window.removeEventListener("mousewheel", this.rdMousewheel);
        $("body").css("overflow-y", "auto");
        $("body").css("padding-right", "0px");
      }
    },
    //监听鼠标滚轮
    rdMousewheel(e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      let rdActive = this.rdActive;
      let rdList = this.rdList;

      if (direction == "down") {
        if (rdActive == rdList.length - 1) this.rdMove(0);
        else this.rdMove(rdActive + 1);
      } else if (direction == "up") {
        if (rdActive == 0) this.rdMove(rdList.length - 1);
        else this.rdMove(rdActive - 1);
      }
    },
    //轮播图移动
    rdMove(i) {
      if (this.rdInterval) clearInterval(this.rdInterval);

      this.rdActive = i;
      let dom = this.$refs.recommended;
      if (!dom) return;
      let left_ts = i ? i * 280 + 350 : 350;
      dom.style.transform = `translate3d(-${left_ts}px, 0px, 0px)`;

      this.rdAutoMove();
    },
    //轮播图自动滚动
    rdAutoMove() {
      this.$nextTick(() => {
        let rdActive = this.rdActive;
        let rdList = this.rdList;

        if (this.rdInterval) clearInterval(this.rdInterval);
        this.rdInterval = setInterval(() => {
          if (rdActive == rdList.length - 1) this.rdChoose(0, "");
          else this.rdChoose(rdActive + 1, "");
        }, this.rdSlideshow);
      })
    },
    ////////////////////////////////分类
    //选择分类
    chooseCatalog(i) {
      this.catActive = i;

      //关键词
      let storeBooks = this.allStoreBooks.filter((item) => item.name.indexOf(this.keyword) > -1);
      const catalog = this.catalogList[i];

      if (catalog.id) {
        storeBooks = storeBooks.filter((item) => item.types.indexOf(catalog.id) > -1);
      }

      this.storeBooks = storeBooks;
      this.maxPage = Math.ceil(this.storeBooks.length / this.pageSize);

      this.needScroll = false;
      this.changePage(1);

      this.loadding = false;
    },
    //翻页
    changePage(page) {
      if (page <= 0) return this.$message.warning("已经是第一页了");
      else if (page > 1 && page > this.maxPage) return this.$message.warning("已经是最后一页了");
      this.page = page;

      let pageSize = this.pageSize;
      let storeBooks = JSON.parse(JSON.stringify(this.storeBooks));
      this.showList = storeBooks.slice((page - 1) * pageSize, page * pageSize);
      this.page = page;

      if (this.needScroll) {
        if (this.phoneMode) {
          $("body").animate({ scrollTop: 450 }, 300);
        } else {
          $("body").animate({ scrollTop: 870 }, 300);
        }
      }
      this.needScroll = true;
    },
    //滚动条移动
    catalogScroll(type) {
      let scroll = $(".catalog").scrollLeft();
      if (type == "left")
        $(".catalog").animate({ scrollLeft: scroll - 700 }, 300);
      else if (type == "right")
        $(".catalog").animate({ scrollLeft: scroll + 700 }, 300);
    },

    //跳转
    routerCourse(item) {
      const { isPaid } = item;
      if (isPaid) {
        this.routerStudy(item);
      }
      else {
        this.routerInfo(item);
      }
    },
    //跳转详情
    routerInfo(item) {
      const { storeUrl, isOtherStore } = item;

      const url = `course-info/${item.pk_store_book_id}`
      if (isOtherStore) {
        if (!this.userInfo) this.$router.push({ path: 'login' });
        else window.open(`${storeUrl}/${url}`);
      }
      else {
        $("body").css("overflow-y", "auto");
        $("body").css("padding-right", "0px");
        this.$router.push({ path: `course-info/${item.pk_store_book_id}` });
      }
    },
    //学习
    routerStudy(item) {
      const { pk_store_book_id, system_id, single_url, storeUrl, isOtherStore } = item;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }

      if (isOtherStore) {
        url = `${storeUrl}${url}`
      }
      window.open(url)
    },


    //关键词搜索
    onSearch() {
      this.chooseCatalog(this.catActive);
    },
    //跳转
    routerPath(url) {
      this.$router.push({ path: url });
    },
    //////////////////////////////卡片轮播
    //判断初始card位移
    judgeCardMove() {
      this.cardTransform = 1150 - window.innerWidth / 2;

      setTimeout(() => {
        this.cardMove(0);
      }, 1000);

      let that = this;
      $(window).resize(function () {
        that.cardTransform = 1150 - window.innerWidth / 2;
        that.cardMove(that.cardActive);
      });
    },
    //选择卡片
    cardChoose(i) {
      if (this.phoneMode) return;

      let cardActive = this.cardActive;
      if (i == cardActive) return;

      let dom = this.$refs.card;
      if (!dom) return;
      dom.style.transitionDuration = `.3s`;
      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `0s`;
      }, 400);

      this.cardMove(i);
    },
    //选择占位
    holderCard(i) {
      let cardActive = this.cardActive;
      if (i == cardActive) return;

      this.cardMove(i);
    },
    //轮播图移动
    cardMove(i) {
      this.cardActive = i;
      let dom = this.$refs.card;
      let ctf = this.cardTransform;
      if (!dom) return;

      let left_ts = 0 - (i * 440 + ctf + 20);
      dom.style.transform = `translate3d(${left_ts}px, 0px, 0px)`;
    },
  },
  beforeDestroy() {
    //去除轮训
    if (this.rdInterval) clearInterval(this.rdInterval);
    if (this.columnInterval) clearInterval(this.columnInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
