<template>
  <div class="audio-player">
    <audio controls controlslist="nodownload" ref="audio">
      <source :src="item.audio">
    </audio>
    <div id="subtitles" v-if="showSubtitle && currentSubtitle">
      {{ currentSubtitle }}
    </div>
    <div class="setting" v-if="item.subtitleOriginal && item.subtitleOriginal.length">
      显示字幕： <input type="checkbox" v-model="showSubtitle" @change="onChange()">
    </div>
  </div>

</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentSubtitle: '',
      showSubtitle: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$refs.audio && this.item.subtitleOriginal) {
        this.setSubtitle()
      }
    })
  },
  methods: {
    setSubtitle() {
      const audio = this.$refs.audio
      audio.addEventListener('timeupdate', () => {
        const currentTime = audio.currentTime

        const currentSubtitle = this.item.subtitleOriginal.find(subtitle => {
          return currentTime >= this.timeStringToSeconds(subtitle.start) && currentTime <= this.timeStringToSeconds(subtitle.end)
        })
        if(currentSubtitle) {
          this.currentSubtitle = currentSubtitle.text
        }
        else {
          this.currentSubtitle = ''
        }
      })
    },
    onChange() {
      const video = this.$refs.video
      if(this.showSubtitle) {
        video.textTracks[0].mode = 'showing'
      }
      else {
        video.textTracks[0].mode = 'hidden'
      }
    },
    timeStringToSeconds(timeString) {
      const [hours, minutes, seconds] = timeString.split(':').map(parseFloat)
      return hours * 3600 + minutes * 60 + seconds
    }
  }
}
</script>
<style scoped>
.audio-player
{
  position: relative;
  width: fit-content;
}

#subtitles
{
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 18px;
  text-align: center;
}

.setting
{
  font-size: 14px;
  display: flex;
  align-items: center;
}

input[type="checkbox"]
{
  width: 20px;
  height: 20px;
  background-color: #ccc;
}

input[type="checkbox"]:checked
{
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
</style>