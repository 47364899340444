<template>
    <div class="new-popup-box" @click="close">
        <div class="hanlder-box">
            <svg class="icon-svg popup-close" @click="close">
                <use xlink:href="#icon-guanbi" />
            </svg>
        </div>
        <div class="box-content share-box fadeIn" :class="{ 'downing': downing }" @click.stop>
            <div class=" book-info">
                <img class="book-cover" :src="book.extension_info.cover" alt />
                <div class="book-name">{{ book.name }}</div>
                <div class="book-author" v-if="book.chief_editor_name">
                    主编：{{ book.chief_editor_name }}
                </div>
                <div class="book-publisher" v-if="book.publisherName">
                    {{ book.publisherName }}
                </div>
                <div class="book-hanlder" v-if="!downing">
                    <button class="book-hanlder-btn" @click="copyLink">复制分享链接</button>
                    <button class="book-hanlder-btn" @click="downloadImage">下载分享图片</button>
                </div>
            </div>
            <div class="share-info">
                <div class="user-info">
                    <img class="user-avatar" :src="userInfo.HeadPhotoPath" alt />
                    <div class="user-name">{{ userInfo.Name }}推荐</div>
                </div>
                <div class="qrcode-box">
                    <vue-qr class="qrCode" :text="shareUrl" :size="phoneMode ? 70 : 120"
                        :margin="phoneMode ? 5 : 10"></vue-qr>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import store from "@/store";
import html2canvas from 'html2canvas';
import VueQr from 'vue-qr/src/packages/vue-qr.vue';

const props = defineProps({
    book: Object,
})
const emit = defineEmits(['close', 'joinSuccess'])
const downing = ref(false);

const shareUrl = ref('');
const userInfo = ref(store.state.userInfo);
const phoneMode = ref(store.state.phoneMode);
const storeUrl = ref(store.state.storeUrl);


onMounted(() => {
    const { pk_store_book_id, isOtherStore } = props.book;
    let url = `${pk_store_book_id}`;
    if (isOtherStore) {
        url = `${props.book.storeUrl}/course-info/${url}`
    }
    else {
        url = `${storeUrl.value}/course-info/${url}`
    }
    shareUrl.value = url;
})
//搜索班级
function copyLink() {
    // 添加复制链接到剪贴板的功能
    navigator.clipboard.writeText(shareUrl.value)
        .then(() => {
            message.success('分享链接已复制');
        })
        .catch((err) => {
            message.error('复制失败');
        });
}
// ... existing code ...

async function downloadImage() {
    if (downing.value) return;
    downing.value = true;

    const captureElement = document.querySelector('.box-content');

    await new Promise(resolve => setTimeout(resolve, 100));

    html2canvas(captureElement, {
        useCORS: true, // 允许跨域图片
        backgroundColor: null, // 透明背景
        scale: 2 // 高清截图
    }).then(canvas => {
        const link = document.createElement('a');
        link.download = `${props.book.name}_${new Date().getTime()}.png`;
        link.href = canvas.toDataURL();
        link.click();
        message.success('图片下载成功');
        downing.value = false;
    }).catch(() => {
        message.error('图片生成失败');
        downing.value = false;
    })
}

function close() {
    emit('close');
}

</script>

<style lang="scss" scoped>
.new-popup-box {
    .share-box {
        width: 600px;
        padding: 0;
        height: auto;
        color: #333333;
        background: radial-gradient(circle, #ffffff 0%, #dbd4e0cc 100%);
        border-radius: 20px;

        &.downing {
            background: #dbd4e0;
            border-radius: 0;

            .book-info {
                .book-hanlder {
                    opacity: 0;
                }
            }
        }

        .book-info {
            text-align: center;
            height: 650px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .book-cover {
                border-radius: 5px;
                width: 233px;
                height: 350px;
                margin-bottom: 20px;
                object-fit: cover;
            }

            .book-name {
                font-size: 24px;
                font-weight: 700;
                margin-bottom: 10px;

                .book-author {
                    margin-bottom: 10px;
                }
            }

            .book-hanlder {
                margin-top: 40px;

                .book-hanlder-btn {
                    color: #B2AFB5;
                    border: 1px solid #B2AFB5;
                    background-color: transparent;
                    margin: 0 10px;
                    padding: 7px 14px;
                    border-radius: 7px;
                    transition: all .3s ease;
                    cursor: pointer;
                    font-size: 14px;

                    &:hover {
                        color: var(--primary-color);;
                        border: 1px solid var(--primary-color);;
                    }
                }
            }
        }

        .share-info {
            height: 150px;
            padding: 0 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #ffffffc9;

            .user-info {
                display: flex;
                align-items: center;
                font-size: 16px;

                .user-avatar {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 10px;
                }
            }

            .qrcode {
                width: 120px;
                height: 120px;
                margin-left: 20px;
            }
        }
    }
}

//手机端
#content {
    &.phone-mode {
        .share-box {
            width: 360px;
            font-size: 12px;

            .book-info {
                height: 380px;

                .book-cover {
                    width: 150px;
                    height: 200px;
                }

                .book-name {
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .book-hanlder {
                    margin-top: 10px;

                    .book-hanlder-btn {
                        padding: 5px 10px;
                        border-radius: 5px;
                        font-size: 12px;
                    }
                }
            }

            .share-info {
                height: 100px;
                padding: 0 20px;

                .user-info {

                    .user-avatar {
                        width: 30px;
                        height: 30px;
                    }
                }

                .qrcode {
                    width: 70px;
                    height: 70px;
                    margin-left: 10px;
                }
            }
        }
    }
}
</style>