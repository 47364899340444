<template>
  <div class="mycourse fadeIn">
    <div class="course-box">
      <div class="course-item" :style="{ backgroundImage: 'url(' + book.extension_info.cover + ')' }"
        v-for="book of storeBooks" :key="book.fk_class_id" @click="routerStudy(book)">
        <div class="course-top">
          <a-tooltip title="分享">
            <div class="svg-box" @click.stop="shareBook = book">
              <svg class="icon-svg" :style="{ fontSize: '16px' }">
                <use xlink:href="#icon-share"></use>
              </svg>
            </div>
          </a-tooltip>
          <a-tooltip title="阅读数据" v-if="!book.single_url">
            <div class="svg-box" @click.stop="portraitsBook = book">
              <svg class="icon-svg">
                <use xlink:href="#icon-statistics"></use>
              </svg>
            </div>
          </a-tooltip>
          <a-tooltip title="详情">
            <div class="svg-box" @click.stop="routerInfo(book)">
              <svg class="icon-svg">
                <use xlink:href="#icon-info"></use>
              </svg>
            </div>
          </a-tooltip>
        </div>
        <div class="course-bottom">
          <div class="join-class-box" v-if="!book.single_url">
            <div class="join-class" v-if="!book.fk_book_class_id">
              <a-tooltip title="加入教材班">
                <div class="svg-box" @click.stop="joinBook = book">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-join-class"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
            <div class="join-class joined" v-else>
              <a-tooltip title="已加入教材班">
                <div class="svg-box">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-student-count"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
          </div>
        </div>
      </div>

      <div class="empty-box" v-if="storeBooks.length == 0">
        <img :src="empty_image" alt />
        <div>暂无购买教材，请前往教材商店</div>
        <a-button type="primary" size="large" class="lp-button" @click="routerPath('home')">教材商店</a-button>
      </div>
    </div>
    <foot></foot>


    <JoinClass :joinBook="joinBook" @closebox="joinBook = null" @joinSuccess="joinSuccess" v-if="joinBook">
    </JoinClass>

    <!-- 学生画像 -->
    <div class="new-popup-box portraits-box fadeIn" v-if="portraitsBook">
      <div class="hanlder-box">
        <svg class="icon-svg popup-close" @click="portraitsBook = null">
          <use xlink:href="#icon-guanbi" />
        </svg>
      </div>
      <div class="box-content">
        <PortraitsContent :currentBook="portraitsBook"></PortraitsContent>
      </div>
    </div>

    <ShareBook :book="shareBook" @close="shareBook = null" v-if="shareBook"></ShareBook>
  </div>
</template>

<script>
import { CloudDownloadOutlined } from '@ant-design/icons-vue';

import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import JoinClass from "@/component/mycourse/JoinClass.vue";
import PortraitsContent from "@/component/personal/portraits-info/portraits-content.vue";
import ShareBook from "@/component/public/ShareBook.vue";
import store from "@/store";

export default {
  components: {
    CloudDownloadOutlined,
    foot,
    navroot,
    JoinClass,
    PortraitsContent,
    ShareBook
  },
  data() {
    return {
      storeBooks: [],

      empty_image: "https://img.lessonplan.cn/IMG/yuanxi/images/empty.png",
      backgroundImage: "https://img.lessonplan.cn/IMG/yuanxi/images/my-book/background.jpg",
      keyword: "",

      userInfo: null,
      userGuid: "",

      finishStatus: null,

      loading: true,
      updateDgUser: false,
      phoneMode: false,
      config: null,

      isSearching: false,
      joinBook: null,
      portraitsBook: null,
      shareBook: null,

      storeUrl: store.state.storeUrl,
    };
  },
  mounted() {
    const { userInfo, storeBooks, phoneMode } = this.$store.state;

    if (!userInfo) {
      this.$router.push({ path: `login` });
    }

    this.userInfo = userInfo;
    this.userGuid = userInfo.PK_UserGuid;

    this.phoneMode = phoneMode;
    this.$store.commit('showReturn', true);

    const newStoreBooks = storeBooks.filter((item) => item.isPaid);
    this.storeBooks = JSON.parse(JSON.stringify(newStoreBooks));
  },
  methods: {
    //跳转详情
    routerInfo(item) {
      const { storeUrl, isOtherStore } = item;

      const url = `/course-info/${item.pk_store_book_id}`
      if (isOtherStore) {
        window.open(`${storeUrl}${url}`);
      }
      else {
        $("body").css("overflow-y", "auto");
        $("body").css("padding-right", "0px");
        this.$router.push({ path: `course-info/${item.pk_store_book_id}` });
      }
    },
    //选择教材
    routerStudy(item) {
      const { pk_store_book_id, system_id, single_url, storeUrl, isOtherStore } = item;
      let url = `/bookReader/${pk_store_book_id}`;
      if (single_url) {
        url = `${single_url}?pk_store_book_id=${pk_store_book_id}&system_id=${system_id}`;
      }

      if (isOtherStore) {
        url = `${storeUrl}${url}`
      }
      window.open(url)
    },
    //跳转
    routerPath(url) {
      this.$router.push({ path: url });
    },
    joinSuccess(joinClass) {
      const { fk_publishing_id, pk_book_class_id } = joinClass;
      this.storeBooks.forEach((item) => {
        if (item.fk_publishing_id == fk_publishing_id) {
          item.fk_book_class_id = pk_book_class_id;
        }
      })

      const newStoreBooks = JSON.parse(JSON.stringify(this.$store.state.storeBooks));
      newStoreBooks.forEach((item) => {
        if (item.fk_publishing_id == fk_publishing_id) {
          item.fk_book_class_id = pk_book_class_id;
        }
      })
      this.$store.commit("setStoreBooks", newStoreBooks);

      this.joinBook = null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./mycourse.scss";
</style>