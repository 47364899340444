<template>
  <div class="foot-box" :style="{ 'background-color': currentStore?.page_config?.foot_background_color }">
    <div class="foot">
      <div class="area-box">
        <div class="logo-box">
          <div class="logo">
            <img class="logo-icon" :src="currentStore?.page_config?.nav_logo" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store";

export default {
  name: 'foot',
  data() {
    return {
      qrcode: '',
      currentStore: store.state.currentStore,
    }
  },
  mounted() {
    //监听store
    // this.$store.subscribe((res) => {
    //   if (!res) return;
    //   let { type, payload } = res;
    // })
  },
  methods: {
    //选择菜单
    chooseMenu(type, item) {
      if (type == 'link') window.open(item.url);
      else this.qrcode = `https://img.lessonplan.cn/IMG/yuanxi/images/home/qrcode/${item.name}.jpg`;
    },
    //打开出版物经营许可证
    openCode(url) {
      this.qrcode = url;
    },
    //打开链接
    openUrl(url) {
      window.open(url);
    }
  }
}
</script>
<style scoped lang="scss">
@import "./foot.scss";
</style>