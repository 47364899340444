<template>
    <iframe class="no-image full-iframe" :src="bookUrl" frameborder="0" v-if="bookUrl"></iframe>
</template>

<script>
import api from '@/services';
import { message } from 'ant-design-vue';
import router from '@/router'
import { PASSPORT_CLIENT_SERVER } from '@/config/url';
import store from "@/store";

export default {
    data() {
        return {
            bookId: '',
            bookUrl: '',
            userId: '',
            readerUrl: 'https://textbookreader.lessonplan.cn',
            storeUrl: store.state.storeUrl,
        }
    },
    mounted() {
        const { params } = router.currentRoute.value;
        this.bookId = params.id;

        const { userInfo } = this.$store.state;
        if (!userInfo) {
            location.href = `${PASSPORT_CLIENT_SERVER}/auth/logout?redirectURI=${this.storeUrl}&isTourist=true`;
        }
        else this.userId = userInfo.PK_UserGuid;

        this.getPublishing();
    },
    methods: {
        async getPublishing() {
            const res = await api.bookManage.storeBookFindOne(this.bookId)
            if (res.code !== 1) return message.error(res.msg)

            const { fk_publishing_id, fk_store_id, repository } = res.data
            const appBaseUrl = `${this.readerUrl}/reader2/App`
            this.bookUrl = `${this.readerUrl}/reader2?baseUrl=${repository.url}&textbookId=${fk_publishing_id}&appBaseUrl=${appBaseUrl}&userId=${this.userId}&storeId=${fk_store_id}`
        }
    }
}
</script>

<style lang="scss" scoped>
.full-iframe {
    width: 100%;
    height: 100%;
    background: #f7f3f2;
}
</style>