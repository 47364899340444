export const EMOJI_LIST_COMMON = [
  { ico: '📚', shortcode: 'books' },
  { ico: '📝', shortcode: 'memo' },
  { ico: '🔍', shortcode: 'mag' },
  { ico: '📈', shortcode: 'chart_with_upwards_trend' },
  { ico: '📉', shortcode: 'chart_with_downwards_trend' },
  { ico: '📊', shortcode: 'bar_chart' },
  { ico: '📌', shortcode: 'pushpin' },
  { ico: '💡', shortcode: 'bulb' },
  { ico: '📢', shortcode: 'loudspeaker' },
  { ico: '📣', shortcode: 'mega' },
  { ico: '👍', shortcode: 'thumbsup' },
  { ico: '👎', shortcode: 'thumbsdown' },
  { ico: '💬', shortcode: 'speech_balloon' },
  { ico: '🔗', shortcode: 'link' },
  { ico: '🤔', shortcode: 'thinking' },
  { ico: '🧐', shortcode: 'monocle_face' },
  { ico: '🤨', shortcode: 'raised_eyebrow' },
  { ico: '🖊️', shortcode: 'pen' },
  { ico: '✏️', shortcode: 'pencil2' },
  { ico: '🖌️', shortcode: 'paintbrush' },
  { ico: '📖', shortcode: 'book' },
  { ico: '🗞️', shortcode: 'newspaper_roll' },
  { ico: '📑', shortcode: 'bookmark_tabs' },
  { ico: '🗂️', shortcode: 'card_index_dividers' },
  { ico: '🗒️', shortcode: 'spiral_notepad' },
  { ico: '❓', shortcode: 'question' },
  { ico: '❗', shortcode: 'exclamation' },
  { ico: '🎯', shortcode: 'dart' },
  { ico: '✔️', shortcode: 'heavy_check_mark' },
  { ico: '❌', shortcode: 'x' },
  { ico: '✅', shortcode: 'white_check_mark' },
  { ico: '❎', shortcode: 'negative_squared_cross_mark' },
  { ico: '⭐', shortcode: 'star' },
  { ico: '🔬', shortcode: 'microscope' },
  { ico: '🧩', shortcode: 'jigsaw' },
  { ico: '🧪', shortcode: 'test_tube' },
  { ico: '🧠', shortcode: 'brain' },
  { ico: '🧮', shortcode: 'abacus' },
  { ico: '🗺️', shortcode: 'world_map' },
  { ico: '🌍', shortcode: 'earth_africa' },
  { ico: '⏳', shortcode: 'hourglass_flowing_sand' },
  { ico: '📜', shortcode: 'scroll' },
  { ico: '✒️', shortcode: 'black_nib' },
  { ico: '🎥', shortcode: 'movie_camera' },
  { ico: '🎧', shortcode: 'headphones' },
  { ico: '💻', shortcode: 'computer' },
  { ico: '🚀', shortcode: 'rocket' },
  { ico: '📏', shortcode: 'straight_ruler' },
  { ico: '📐', shortcode: 'triangular_ruler' },
  { ico: '🛠️', shortcode: 'hammer_and_wrench' },
  { ico: '🎨', shortcode: 'art' },
  { ico: '🖼️', shortcode: 'framed_picture' },
  { ico: '🛑', shortcode: 'stop_sign' },
]

export const EMOJI_LIST_EMOTION = [
  { ico: '😀', shortcode: 'grinning' },
  { ico: '😃', shortcode: 'smiley' },
  { ico: '😄', shortcode: 'smile' },
  { ico: '😁', shortcode: 'grin' },
  { ico: '😆', shortcode: 'laughing' },
  { ico: '😅', shortcode: 'sweat_smile' },
  { ico: '🤣', shortcode: 'rofl' },
  { ico: '😂', shortcode: 'joy' },
  { ico: '🙂', shortcode: 'slightly_smiling_face' },
  { ico: '🙃', shortcode: 'upside_down_face' },
  { ico: '😉', shortcode: 'wink' },
  { ico: '😊', shortcode: 'blush' },
  { ico: '😇', shortcode: 'innocent' },
  { ico: '🥰', shortcode: 'smiling_face_with_three_hearts' },
  { ico: '😍', shortcode: 'heart_eyes' },
  { ico: '🤩', shortcode: 'star_struck' },
  { ico: '😘', shortcode: 'kissing_heart' },
  { ico: '😚', shortcode: 'kissing_closed_eyes' },
  { ico: '😙', shortcode: 'kissing_smiling_eyes' },
  { ico: '😋', shortcode: 'yum' },
  { ico: '😛', shortcode: 'stuck_out_tongue' },
  { ico: '😜', shortcode: 'stuck_out_tongue_winking_eye' },
  { ico: '🤪', shortcode: 'zany_face' },
  { ico: '😝', shortcode: 'stuck_out_tongue_closed_eyes' },
  { ico: '🤑', shortcode: 'money_mouth_face' },
  { ico: '🤗', shortcode: 'hugs' },
  { ico: '🤭', shortcode: 'hand_over_mouth' },
  { ico: '🤫', shortcode: 'shushing_face' },
  { ico: '🤔', shortcode: 'thinking' },
  { ico: '🤐', shortcode: 'zipper_mouth_face' },
  { ico: '🤨', shortcode: 'raised_eyebrow' },
  { ico: '😐', shortcode: 'neutral_face' },
  { ico: '😑', shortcode: 'expressionless' },
  { ico: '😶', shortcode: 'no_mouth' },
  { ico: '😏', shortcode: 'smirk' },
  { ico: '😒', shortcode: 'unamused' },
  { ico: '🙄', shortcode: 'roll_eyes' },
  { ico: '😬', shortcode: 'grimacing' },
  { ico: '🤥', shortcode: 'lying_face' },
  { ico: '😌', shortcode: 'relieved' },
  { ico: '😔', shortcode: 'pensive' },
  { ico: '😪', shortcode: 'sleepy' },
  { ico: '🤤', shortcode: 'drooling_fac' },
  { ico: '😴', shortcode: 'sleeping' },
  { ico: '😷', shortcode: 'mask' },
  { ico: '🤒', shortcode: 'face_with_thermometer' },
  { ico: '🤕', shortcode: 'face_with_head_bandage' },
  { ico: '🤢', shortcode: 'nauseated_face' },
  { ico: '🤮', shortcode: 'vomiting_face' },
  { ico: '🤧', shortcode: 'sneezing_face' },
  { ico: '🥶', shortcode: 'cold_face' },
  { ico: '🥴', shortcode: 'woozy_face' },
  { ico: '😵', shortcode: 'dizzy_face' },
  { ico: '🤯', shortcode: 'exploding_head' },
  { ico: '🤠', shortcode: 'cowboy_hat_face' },
  { ico: '🥳', shortcode: 'partying_face' },
  { ico: '😎', shortcode: 'sunglasses' },
  { ico: '🤓', shortcode: 'nerd_face' },
  { ico: '🧐', shortcode: 'monocle_face' },
  { ico: '😕', shortcode: 'confused' },
  { ico: '😟', shortcode: 'worried' },
  { ico: '🙁', shortcode: 'slightly_frowning_face' },
  { ico: '☹️', shortcode: 'frowning_face' },
  { ico: '😮', shortcode: 'open_mouth' },
  { ico: '😯', shortcode: 'hushed' },
  { ico: '😲', shortcode: 'astonished' },
  { ico: '😳', shortcode: 'flushed' },
  { ico: '🥺', shortcode: 'pleading_face' },
  { ico: '😦', shortcode: 'frowning' },
  { ico: '😧', shortcode: 'anguished' },
  { ico: '😨', shortcode: 'fearful' },
  { ico: '😰', shortcode: 'cold_sweat' },
  { ico: '😥', shortcode: 'disappointed_relieved' },
  { ico: '😢', shortcode: 'cry' },
  { ico: '😭', shortcode: 'sob' },
  { ico: '😱', shortcode: 'scream' },
  { ico: '😖', shortcode: 'confounded' },
  { ico: '😣', shortcode: 'persevere' },
  { ico: '😞', shortcode: 'disappointed' },
  { ico: '😓', shortcode: 'sweat' },
  { ico: '😩', shortcode: 'weary' },
  { ico: '😫', shortcode: 'tired_face' },
  { ico: '😤', shortcode: 'triumph' },
  { ico: '😠', shortcode: 'angry' },
  { ico: '😡', shortcode: 'pout' },
  { ico: '🥵', shortcode: 'hot_face' },
  { ico: '🤬', shortcode: 'cursing_face' },
  { ico: '😈', shortcode: 'smiling_imp' },
  { ico: '👿', shortcode: 'imp' },
  { ico: '💀', shortcode: 'skull' },
  { ico: '☠️', shortcode: 'skull_and_crossbones' },
  { ico: '🤡', shortcode: 'clown_face' },
  { ico: '👹', shortcode: 'japanese_ogre' },
  { ico: '👺', shortcode: 'japanese_goblin' },
  { ico: '👻', shortcode: 'ghost' },
  { ico: '👽', shortcode: 'alien' },
  { ico: '👾', shortcode: 'space_invader' },
  { ico: '😺', shortcode: 'smiley_cat' },
  { ico: '😸', shortcode: 'smile_cat' },
  { ico: '😹', shortcode: 'joy_cat' },
  { ico: '😻', shortcode: 'heart_eyes_cat' },
  { ico: '😼', shortcode: 'smirk_cat' },
  { ico: '😽', shortcode: 'kissing_cat' },
  { ico: '🙀', shortcode: 'scream_cat' },
  { ico: '😿', shortcode: 'crying_cat_face' },
  { ico: '😾', shortcode: 'pouting_cat' },
  { ico: '🙈', shortcode: 'see_no_evil' },
  { ico: '🙉', shortcode: 'hear_no_evil' },
  { ico: '🙊', shortcode: 'speak_no_evil' },
  { ico: '💌', shortcode: 'love_letter' },
  { ico: '💘', shortcode: 'cupid' },
  { ico: '💝', shortcode: 'gift_heart' },
  { ico: '💖', shortcode: 'sparkling_heart' },
  { ico: '💗', shortcode: 'heartpulse' },
  { ico: '💓', shortcode: 'heartbeat' },
  { ico: '💞', shortcode: 'revolving_hearts' },
  { ico: '💕', shortcode: 'two_hearts' },
  { ico: '💟', shortcode: 'heart_decoration' },
  { ico: '❣️', shortcode: 'heavy_heart_exclamation' },
  { ico: '💔', shortcode: 'broken_heart' },
  { ico: '🧡', shortcode: 'orange_heart' },
  { ico: '💛', shortcode: 'yellow_heart' },
  { ico: '💚', shortcode: 'green_heart' },
  { ico: '💙', shortcode: 'blue_heart' },
  { ico: '💜', shortcode: 'purple_heart' },
  { ico: '🖤', shortcode: 'black_heart' },
  { ico: '💋', shortcode: 'kiss' },
  { ico: '💯', shortcode: '100' },
  { ico: '💢', shortcode: 'anger' },
  { ico: '💥', shortcode: 'boom' },
  { ico: '💫', shortcode: 'dizzy' },
  { ico: '💦', shortcode: 'sweat_drops' },
  { ico: '💨', shortcode: 'dash' },
  { ico: '🕳️', shortcode: ':hole' },
  { ico: '💬', shortcode: 'speech_balloon' },
  { ico: '👁️‍🗨️', shortcode: ':eye_speech_bubble' },
  { ico: '🗨️', shortcode: ':left_speech_bubble' },
  { ico: '🗯️', shortcode: ':right_anger_bubble' },
  { ico: '💭', shortcode: 'thought_balloon' },
  { ico: '💤', shortcode: 'zzz' },
]

export const EMOJI_LIST_ACT = [
  { ico: '👋', shortcode: 'wave' },
  { ico: '🤚', shortcode: 'raised_back_of_hand' },
  { ico: '🖐️', shortcode: 'raised_hand_with_fingers_splayed' },
  { ico: '✋', shortcode: 'hand' },
  { ico: '🖖', shortcode: 'vulcan_salute' },
  { ico: '👌', shortcode: 'ok_hand' },
  { ico: '✌️', shortcode: 'v' },
  { ico: '🤞', shortcode: 'crossed_fingers' },
  { ico: '🤟', shortcode: 'love_you_gesture' },
  { ico: '🤘', shortcode: 'metal' },
  { ico: '🤙', shortcode: 'call_me_hand' },
  { ico: '👈', shortcode: 'point_left' },
  { ico: '👉', shortcode: 'point_right' },
  { ico: '👆', shortcode: 'point_up_2' },
  { ico: '🖕', shortcode: 'fu' },
  { ico: '👇', shortcode: 'point_down' },
  { ico: '☝️', shortcode: 'point_up' },
  { ico: '👍', shortcode: 'thumbsup' },
  { ico: '👎', shortcode: 'thumbsdown' },
  { ico: '✊', shortcode: 'fist' },
  { ico: '👊', shortcode: 'facepunch' },
  { ico: '🤛', shortcode: 'fist_left' },
  { ico: '🤜', shortcode: 'fist_right' },
  { ico: '👏', shortcode: 'clap' },
  { ico: '🙌', shortcode: 'raised_hands' },
  { ico: '👐', shortcode: 'open_hands' },
  { ico: '🤲', shortcode: 'palms_up_together' },
  { ico: '🤝', shortcode: 'handshake' },
  { ico: '🙏', shortcode: 'pray' },
  { ico: '✍️', shortcode: 'writing_hand' },
  { ico: '💅', shortcode: 'nail_care' },
  { ico: '🤳', shortcode: 'selfie' },	
  { ico: '💪', shortcode: 'muscle' },
  { ico: '🦵', shortcode: 'leg' },
  { ico: '🦶', shortcode: 'foot' },
  { ico: '👂', shortcode: 'ear' },
  { ico: '👃', shortcode: 'nose' },
  { ico: '🧠', shortcode: 'brain' },
  { ico: '🦷', shortcode: 'tooth' },
  { ico: '🦴', shortcode: 'bone' },
  { ico: '👀', shortcode: 'eyes' },
  { ico: '👁️', shortcode: 'eye' },
  { ico: '👅', shortcode: 'tongue' },
  { ico: '👄', shortcode: 'lips' },
  { ico: '🤺', shortcode: 'person_fencing' },
  { ico: '🏇', shortcode: 'horse_racing' },
  { ico: '⛷️', shortcode: 'skier' },
  { ico: '🏂', shortcode: 'snowboarder' },
  { ico: '🏌️', shortcode: 'golfing' },
  { ico: '🏌️‍♂️', shortcode: 'golfing_man' },
  { ico: '🏌️‍♀️', shortcode: 'golfing_woman' },
  { ico: '🏄', shortcode: 'surfer' },
  { ico: '🏄‍♂️', shortcode: 'surfing_man' },
  { ico: '🏄‍♀️', shortcode: 'surfing_woman' },
  { ico: '🚣', shortcode: 'rowboat' },
  { ico: '🚣‍♂️', shortcode: 'rowing_man' },
  { ico: '🚣‍♀️', shortcode: 'rowing_woman' },
  { ico: '🏊', shortcode: 'swimmer' },
  { ico: '🏊‍♂️', shortcode: 'swimming_man' },
  { ico: '🏊‍♀️', shortcode: 'swimming_woman' },
  { ico: '⛹️', shortcode: 'bouncing_ball_person' },
  { ico: '⛹️‍♂️', shortcode: 'basketball_man' },
  { ico: '⛹️‍♀️', shortcode: 'basketball_woman' },
  { ico: '🏋️', shortcode: 'weight_lifting' },
  { ico: '🏋️‍♂️', shortcode: 'weight_lifting_man' },
  { ico: '🏋️‍♀️', shortcode: 'weight_lifting_woman' },
  { ico: '🚴', shortcode: 'bicyclist' },
  { ico: '🚴‍♂️', shortcode: 'biking_man' },
  { ico: '🚴‍♀️', shortcode: 'biking_woman' },
  { ico: '🚵', shortcode: 'mountain_bicyclist' },
  { ico: '🚵‍♂️', shortcode: 'mountain_biking_man' },
  { ico: '🚵‍♀️', shortcode: 'mountain_biking_woman' },
  { ico: '🤸', shortcode: 'cartwheeling' },
  { ico: '🤸‍♂️', shortcode: 'man_cartwheeling' },
  { ico: '🤸‍♀️', shortcode: 'woman_cartwheeling' },
  { ico: '🤼', shortcode: 'wrestling' },
  { ico: '🤼‍♂️', shortcode: 'men_wrestling' },
  { ico: '🤼‍♀️', shortcode: 'women_wrestling' },
  { ico: '🤽', shortcode: 'water_polo' },
  { ico: '🤽‍♂️', shortcode: 'man_playing_water_polo' },
  { ico: '🤽‍♀️', shortcode: 'woman_playing_water_polo' },
  { ico: '🤾', shortcode: 'handball_person' },
  { ico: '🤾‍♂️', shortcode: 'man_playing_handball' },
  { ico: '🤾‍♀️', shortcode: 'woman_playing_handball' },
  { ico: '🤹', shortcode: 'juggling_person' },
  { ico: '🤹‍♂️', shortcode: 'man_juggling' },
  { ico: '🤹‍♀️', shortcode: 'woman_juggling' },
  { ico: '🧘', shortcode: 'lotus_position' },
  { ico: '🧘‍♂️', shortcode: 'lotus_position_man' },
  { ico: '🧘‍♀️', shortcode: 'lotus_position_woman' },
  { ico: '🛀', shortcode: 'bath' },
  { ico: '🛌', shortcode: 'sleeping_bed' },
]

export const EMOJI_LIST_NATURE = [
  { ico: '🐵', shortcode: 'monkey_face' },
  { ico: '🐒', shortcode: 'monkey' },
  { ico: '🦍', shortcode: 'gorilla' },
  { ico: '🐶', shortcode: 'dog' },
  { ico: '🐕', shortcode: 'dog2' },
  { ico: '🐩', shortcode: 'poodle' },
  { ico: '🐺', shortcode: 'wolf' },
  { ico: '🦊', shortcode: 'fox_face' },
  { ico: '🦝', shortcode: 'raccoon' },
  { ico: '🐱', shortcode: 'cat' },
  { ico: '🐈', shortcode: 'cat2' },
  { ico: '🦁', shortcode: 'lion' },
  { ico: '🐯', shortcode: 'tiger' },
  { ico: '🐅', shortcode: 'tiger2' },
  { ico: '🐆', shortcode: 'leopard' },
  { ico: '🐴', shortcode: 'horse' },
  { ico: '🐎', shortcode: 'racehorse' },
  { ico: '🦄', shortcode: 'unicorn' },
  { ico: '🦓', shortcode: 'zebra' },
  { ico: '🦌', shortcode: 'deer' },
  { ico: '🐮', shortcode: 'cow' },
  { ico: '🐂', shortcode: 'ox' },
  { ico: '🐃', shortcode: 'water_buffalo' },
  { ico: '🐄', shortcode: 'cow2' },
  { ico: '🐷', shortcode: 'pig' },
  { ico: '🐖', shortcode: 'pig2' },
  { ico: '🐗', shortcode: 'boar' },
  { ico: '🐽', shortcode: 'pig_nose' },
  { ico: '🐏', shortcode: 'ram' },
  { ico: '🐑', shortcode: 'sheep' },
  { ico: '🐐', shortcode: 'goat' },
  { ico: '🐪', shortcode: 'dromedary_camel' },
  { ico: '🐫', shortcode: 'camel' },
  { ico: '🦙', shortcode: 'llama' },
  { ico: '🦒', shortcode: 'giraffe' },
  { ico: '🐘', shortcode: 'elephant' },
  { ico: '🦏', shortcode: 'rhinoceros' },
  { ico: '🦛', shortcode: 'hippopotamus' },
  { ico: '🐭', shortcode: 'mouse' },
  { ico: '🐁', shortcode: 'mouse2' },
  { ico: '🐀', shortcode: 'rat' },
  { ico: '🐹', shortcode: 'hamster' },
  { ico: '🐰', shortcode: 'rabbit' },
  { ico: '🐇', shortcode: 'rabbit2' },
  { ico: '🐿️', shortcode: 'chipmunk' },
  { ico: '🦔', shortcode: 'hedgehog' },
  { ico: '🦇', shortcode: 'bat' },
  { ico: '🐻', shortcode: 'bear' },
  { ico: '🐨', shortcode: 'koala' },
  { ico: '🐼', shortcode: 'panda_face' },
  { ico: '🦘', shortcode: 'kangaroo' },
  { ico: '🦡', shortcode: 'badger' },
  { ico: '🐾', shortcode: 'feet' },
  { ico: '🦃', shortcode: 'turkey' },
  { ico: '🐔', shortcode: 'chicken' },
  { ico: '🐓', shortcode: 'rooster' },
  { ico: '🐣', shortcode: 'hatching_chick' },
  { ico: '🐤', shortcode: 'baby_chick' },
  { ico: '🐥', shortcode: 'hatched_chick' },
  { ico: '🐦', shortcode: 'bird' },
  { ico: '🐧', shortcode: 'penguin' },
  { ico: '🕊️', shortcode: 'dove' },
  { ico: '🦅', shortcode: 'eagle' },
  { ico: '🦆', shortcode: 'duck' },
  { ico: '🦢', shortcode: 'swan' },
  { ico: '🦉', shortcode: 'owl' },
  { ico: '🦚', shortcode: 'peacock' },
  { ico: '🦜', shortcode: 'parrot' },
  { ico: '🐸', shortcode: 'frog' },
  { ico: '🐊', shortcode: 'crocodile' },
  { ico: '🐢', shortcode: 'turtle' },
  { ico: '🦎', shortcode: 'lizard' },
  { ico: '🐍', shortcode: 'snake' },
  { ico: '🐲', shortcode: 'dragon_face' },
  { ico: '🐉', shortcode: 'dragon' },
  { ico: '🦕', shortcode: 'sauropod' },
  { ico: '🦖', shortcode: 't-rex' },
  { ico: '🐳', shortcode: 'whale' },
  { ico: '🐋', shortcode: 'whale2' },
  { ico: '🐬', shortcode: 'dolphin' },
  { ico: '🐟', shortcode: 'fish' },
  { ico: '🐠', shortcode: 'tropical_fish' },
  { ico: '🐡', shortcode: 'blowfish' },
  { ico: '🦈', shortcode: 'shark' },
  { ico: '🐙', shortcode: 'octopus' },
  { ico: '🐚', shortcode: 'shell' },
  { ico: '🐌', shortcode: 'snail' },
  { ico: '🦋', shortcode: 'butterfly' },
  { ico: '🐛', shortcode: 'bug' },
  { ico: '🐜', shortcode: 'ant' },
  { ico: '🐝', shortcode: 'bee' },
  { ico: '🐞', shortcode: 'lady_beetle' },
  { ico: '🦗', shortcode: 'cricket' },
  { ico: '🪳', shortcode: 'cockroach' },
  { ico: '🕷️', shortcode: 'spider' },
  { ico: '🕸️', shortcode: 'spider_web' },
  { ico: '🦂', shortcode: 'scorpion' },
  { ico: '🦟', shortcode: 'mosquito' },
  { ico: '🦠', shortcode: 'microbe' },
  { ico: '💐', shortcode: 'bouquet' },
  { ico: '🌸', shortcode: 'cherry_blossom' },
  { ico: '💮', shortcode: 'white_flower' },
  { ico: '🏵️', shortcode: 'rosette' },
  { ico: '🌹', shortcode: 'rose' },
  { ico: '🥀', shortcode: 'wilted_flower' },
  { ico: '🌺', shortcode: 'hibiscus' },
  { ico: '🌻', shortcode: 'sunflower' },
  { ico: '🌼', shortcode: 'blossom' },
  { ico: '🌷', shortcode: 'tulip' },
  { ico: '🌱', shortcode: 'seedling' },
  { ico: '🌲', shortcode: 'evergreen_tree' },
  { ico: '🌳', shortcode: 'deciduous_tree' },
  { ico: '🌴', shortcode: 'palm_tree' },
  { ico: '🌵', shortcode: 'cactus' },
  { ico: '🌾', shortcode: 'ear_of_rice' },
  { ico: '🌿', shortcode: 'herb' },
  { ico: '☘️', shortcode: 'shamrock' },
  { ico: '🍀', shortcode: 'four_leaf_clover' },
  { ico: '🍁', shortcode: 'maple_leaf' },
  { ico: '🍂', shortcode: 'fallen_leaf' },
  { ico: '🍃', shortcode: 'leaves' },
  { ico: '🍄', shortcode: 'mushroom' },
]

export const EMOJI_LIST_FOOD = [
  { ico: '🍇', shortcode: 'grapes' },
  { ico: '🍈', shortcode: 'melon' },
  { ico: '🍉', shortcode: 'watermelon' },
  { ico: '🍊', shortcode: 'mandarin' },
  { ico: '🍋', shortcode: 'lemon' },
  { ico: '🍌', shortcode: 'banana' },
  { ico: '🍍', shortcode: 'pineapple' },
  { ico: '🥭', shortcode: 'mango' },
  { ico: '🍎', shortcode: 'apple' },
  { ico: '🍏', shortcode: 'green_apple' },
  { ico: '🍐', shortcode: 'pear' },
  { ico: '🍑', shortcode: 'peach' },
  { ico: '🍒', shortcode: 'cherries' },
  { ico: '🍓', shortcode: 'strawberry' },
  { ico: '🥝', shortcode: 'kiwi_fruit' },
  { ico: '🍅', shortcode: 'tomato' },
  { ico: '🥥', shortcode: 'coconut' },
  { ico: '🥑', shortcode: 'avocado' },
  { ico: '🍆', shortcode: 'eggplant' },
  { ico: '🥔', shortcode: 'potato' },
  { ico: '🥕', shortcode: 'carrot' },
  { ico: '🌽', shortcode: 'corn' },
  { ico: '🌶️', shortcode: 'hot_pepper' },
  { ico: '🥒', shortcode: 'cucumber' },
  { ico: '🥬', shortcode: 'leafy_green' },
  { ico: '🥦', shortcode: 'broccoli' },
  { ico: '🥜', shortcode: 'peanuts' },
  { ico: '🌰', shortcode: 'chestnut' },
  { ico: '🍞', shortcode: 'bread' },
  { ico: '🥐', shortcode: 'croissant' },
  { ico: '🥖', shortcode: 'baguette_bread' },
  { ico: '🥨', shortcode: 'pretzel' },
  { ico: '🥯', shortcode: 'bagel' },
  { ico: '🥞', shortcode: 'pancakes' },
  { ico: '🧀', shortcode: 'cheese' },
  { ico: '🍖', shortcode: 'meat_on_bone' },
  { ico: '🍗', shortcode: 'poultry_leg' },
  { ico: '🥩', shortcode: 'cut_of_meat' },
  { ico: '🥓', shortcode: 'bacon' },
  { ico: '🍔', shortcode: 'hamburger' },
  { ico: '🍟', shortcode: 'fries' },
  { ico: '🍕', shortcode: 'pizza' },
  { ico: '🌭', shortcode: 'hotdog' },
  { ico: '🥪', shortcode: 'sandwich' },
  { ico: '🌮', shortcode: 'taco' },
  { ico: '🌯', shortcode: 'burrito' },
  { ico: '🥙', shortcode: 'stuffed_flatbread' },
  { ico: '🥚', shortcode: 'egg' },
  { ico: '🍳', shortcode: 'fried_egg' },
  { ico: '🥘', shortcode: 'shallow_pan_of_food' },
  { ico: '🍲', shortcode: 'stew' },
  { ico: '🥣', shortcode: 'bowl_with_spoon' },
  { ico: '🥗', shortcode: 'green_salad' },
  { ico: '🍿', shortcode: 'popcorn' },
  { ico: '🧂', shortcode: 'salt' },
  { ico: '🥫', shortcode: 'canned_food' },
  { ico: '🍱', shortcode: 'bento' },
  { ico: '🍘', shortcode: 'rice_cracker' },
  { ico: '🍙', shortcode: 'rice_ball' },
  { ico: '🍚', shortcode: 'rice' },
  { ico: '🍛', shortcode: 'curry' },
  { ico: '🍜', shortcode: 'ramen' },
  { ico: '🍝', shortcode: 'spaghetti' },
  { ico: '🍠', shortcode: 'sweet_potato' },
  { ico: '🍢', shortcode: 'oden' },
  { ico: '🍣', shortcode: 'sushi' },
  { ico: '🍤', shortcode: 'fried_shrimp' },
  { ico: '🍥', shortcode: 'fish_cake' },
  { ico: '🥮', shortcode: 'moon_cake' },
  { ico: '🍡', shortcode: 'dango' },
  { ico: '🥟', shortcode: 'dumpling' },
  { ico: '🥠', shortcode: 'fortune_cookie' },
  { ico: '🥡', shortcode: 'takeout_box' },
  { ico: '🦀', shortcode: 'crab' },
  { ico: '🦞', shortcode: 'lobster' },
  { ico: '🦐', shortcode: 'shrimp' },
  { ico: '🦑', shortcode: 'squid' },
  { ico: '🍦', shortcode: 'icecream' },
  { ico: '🍧', shortcode: 'shaved_ice' },
  { ico: '🍨', shortcode: 'ice_cream' },
  { ico: '🍩', shortcode: 'doughnut' },
  { ico: '🍪', shortcode: 'cookie' },
  { ico: '🎂', shortcode: 'birthday' },
  { ico: '🍰', shortcode: 'cake' },
  { ico: '🧁', shortcode: 'cupcake' },
  { ico: '🥧', shortcode: 'pie' },
  { ico: '🍫', shortcode: 'chocolate_bar' },
  { ico: '🍬', shortcode: 'candy' },
  { ico: '🍭', shortcode: 'lollipop' },
  { ico: '🍮', shortcode: 'custard' },
  { ico: '🍯', shortcode: 'honey_pot' },
  { ico: '🍼', shortcode: 'baby_bottle' },
  { ico: '🥛', shortcode: 'milk_glass' },
  { ico: '🍵', shortcode: 'tea' },
  { ico: '🍶', shortcode: 'sake' },
  { ico: '🍾', shortcode: 'champagne' },
  { ico: '🍷', shortcode: 'wine_glass' },
  { ico: '🍸', shortcode: 'cocktail' },
  { ico: '🍹', shortcode: 'tropical_drink' },
  { ico: '🍺', shortcode: 'beer' },
  { ico: '🍻', shortcode: 'beers' },
  { ico: '🥂', shortcode: 'clinking_glasses' },
  { ico: '🥃', shortcode: 'tumbler_glass' },
  { ico: '🥤', shortcode: 'cup_with_straw' },
  { ico: '🥢', shortcode: 'chopsticks' },
  { ico: '🍽️', shortcode: 'plate_with_cutlery' },
  { ico: '🍴', shortcode: 'fork_and_knife' },
  { ico: '🥄', shortcode: 'spoon' },
  { ico: '🔪', shortcode: 'hocho' },
  { ico: '🏺', shortcode: 'amphora' },
]

export const EMOJI_LIST_PLACE = [
  { ico: '🌍', shortcode: 'earth_africa' },
  { ico: '🌎', shortcode: 'earth_americas' },
  { ico: '🌏', shortcode: 'earth_asia' },
  { ico: '🌐', shortcode: 'globe_with_meridians' },
  { ico: '🗺️', shortcode: 'world_map' },
  { ico: '🗾', shortcode: 'japan' },
  { ico: '🧭', shortcode: 'compass' },
  { ico: '🏔️', shortcode: 'mountain_snow' },
  { ico: '⛰️', shortcode: 'mountain' },
  { ico: '🌋', shortcode: 'volcano' },
  { ico: '🗻', shortcode: 'mount_fuji' },
  { ico: '🏕️', shortcode: 'camping' },
  { ico: '🏖️', shortcode: 'beach_umbrella' },
  { ico: '🏜️', shortcode: 'desert' },
  { ico: '🏝️', shortcode: 'desert_island' },
  { ico: '🏞️', shortcode: 'national_park' },
  { ico: '🏟️', shortcode: 'stadium' },
  { ico: '🏛️', shortcode: 'classical_building' },
  { ico: '🏗️', shortcode: 'building_construction' },
  { ico: '🧱', shortcode: 'bricks' },
  { ico: '🏘️', shortcode: 'houses' },
  { ico: '🏚️', shortcode: 'derelict_house' },
  { ico: '🏠', shortcode: 'house' },
  { ico: '🏡', shortcode: 'house_with_garden' },
  { ico: '🏢', shortcode: 'office' },
  { ico: '🏣', shortcode: 'post_office' },
  { ico: '🏤', shortcode: 'european_post_office' },
  { ico: '🏥', shortcode: 'hospital' },
  { ico: '🏦', shortcode: 'bank' },
  { ico: '🏨', shortcode: 'hotel' },
  { ico: '🏩', shortcode: 'love_hotel' },
  { ico: '🏪', shortcode: 'convenience_store' },
  { ico: '🏫', shortcode: 'school' },
  { ico: '🏬', shortcode: 'department_store' },
  { ico: '🏭', shortcode: 'factory' },
  { ico: '🏯', shortcode: 'japanese_castle' },
  { ico: '🏰', shortcode: 'european_castle' },
  { ico: '💒', shortcode: 'wedding' },
  { ico: '🗼', shortcode: 'tokyo_tower' },
  { ico: '🗽', shortcode: 'statue_of_liberty' },
  { ico: '⛪', shortcode: 'church' },
  { ico: '🕌', shortcode: 'mosque' },
  { ico: '🕍', shortcode: 'synagogue' },
  { ico: '⛩️', shortcode: 'shinto_shrine' },
  { ico: '🕋', shortcode: 'kaaba' },
  { ico: '⛲', shortcode: 'fountain' },
  { ico: '⛺', shortcode: 'tent' },
  { ico: '🌁', shortcode: 'foggy' },
  { ico: '🌃', shortcode: 'night_with_stars' },
  { ico: '🏙️', shortcode: 'cityscape' },
  { ico: '🌄', shortcode: 'sunrise_over_mountains' },
  { ico: '🌅', shortcode: 'sunrise' },
  { ico: '🌆', shortcode: 'city_sunset' },
  { ico: '🌇', shortcode: 'city_sunrise' },
  { ico: '🌉', shortcode: 'bridge_at_night' },
  { ico: '♨️', shortcode: 'hotsprings' },
  { ico: '🎠', shortcode: 'carousel_horse' },
  { ico: '🎡', shortcode: 'ferris_wheel' },
  { ico: '🎢', shortcode: 'roller_coaster' },
  { ico: '💈', shortcode: 'barber' },
  { ico: '🎪', shortcode: 'circus_tent' },
]

export const EMOJI_LIST_TRAVEL = [
  { ico: '🚂', shortcode: 'steam_locomotive' },
  { ico: '🚃', shortcode: 'railway_car' },
  { ico: '🚄', shortcode: 'bullettrain_side' },
  { ico: '🚅', shortcode: 'bullettrain_front' },
  { ico: '🚆', shortcode: 'train2' },
  { ico: '🚇', shortcode: 'metro' },
  { ico: '🚈', shortcode: 'light_rail' },
  { ico: '🚉', shortcode: 'station' },
  { ico: '🚊', shortcode: 'tram' },
  { ico: '🚝', shortcode: 'monorail' },
  { ico: '🚞', shortcode: 'mountain_railway' },
  { ico: '🚋', shortcode: 'train' },
  { ico: '🚌', shortcode: 'bus' },
  { ico: '🚍', shortcode: 'oncoming_bus' },
  { ico: '🚎', shortcode: 'trolleybus' },
  { ico: '🚐', shortcode: 'minibus' },
  { ico: '🚑', shortcode: 'ambulance' },
  { ico: '🚒', shortcode: 'fire_engine' },
  { ico: '🚓', shortcode: 'police_car' },
  { ico: '🚔', shortcode: 'oncoming_police_car' },
  { ico: '🚕', shortcode: 'taxi' },
  { ico: '🚖', shortcode: 'oncoming_taxi' },
  { ico: '🚗', shortcode: 'car' },
  { ico: '🚘', shortcode: 'oncoming_automobile' },
  { ico: '🚙', shortcode: 'blue_car' },
  { ico: '🚚', shortcode: 'truck' },
  { ico: '🚛', shortcode: 'articulated_lorry' },
  { ico: '🚜', shortcode: 'tractor' },
  { ico: '🏎️', shortcode: 'racing_car' },
  { ico: '🏍️', shortcode: 'motorcycle' },
  { ico: '🛵', shortcode: 'motor_scooter' },
  { ico: '🚲', shortcode: 'bike' },
  { ico: '🛴', shortcode: 'kick_scooter' },
  { ico: '🛹', shortcode: 'skateboard' },
  { ico: '🚏', shortcode: 'busstop' },
  { ico: '🛣️', shortcode: 'motorway' },
  { ico: '🛤️', shortcode: 'railway_track' },
  { ico: '🛢️', shortcode: 'oil_drum' },
  { ico: '⛽', shortcode: 'fuelpump' },
  { ico: '🚨', shortcode: 'rotating_light' },
  { ico: '🚥', shortcode: 'traffic_light' },
  { ico: '🚦', shortcode: 'vertical_traffic_light' },
  { ico: '🛑', shortcode: 'stop_sign' },
  { ico: '🚧', shortcode: 'construction' },
  { ico: '⚓', shortcode: 'anchor' },
  { ico: '⛵', shortcode: 'boat' },
  { ico: '🛶', shortcode: 'canoe' },
  { ico: '🚤', shortcode: 'speedboat' },
  { ico: '🛳️', shortcode: 'passenger_ship' },
  { ico: '⛴️', shortcode: 'ferry' },
  { ico: '🛥️', shortcode: 'motor_boat' },
  { ico: '🚢', shortcode: 'ship' },
  { ico: '✈️', shortcode: 'airplane' },
  { ico: '🛩️', shortcode: 'small_airplane' },
  { ico: '🛫', shortcode: 'flight_departure' },
  { ico: '🛬', shortcode: 'flight_arrival' },
  { ico: '💺', shortcode: 'seat' },
  { ico: '🚁', shortcode: 'helicopter' },
  { ico: '🚟', shortcode: 'suspension_railway' },
  { ico: '🚠', shortcode: 'mountain_cableway' },
  { ico: '🚡', shortcode: 'aerial_tramway' },
  { ico: '🛰️', shortcode: 'artificial_satellite' },
  { ico: '🚀', shortcode: 'rocket' },
  { ico: '🛸', shortcode: 'flying_saucer' },
  { ico: '🛎️', shortcode: 'bellhop_bell' },
  { ico: '🧳', shortcode: 'luggage' },
]

export const EMOJI_LIST_TIME = [
  { ico: '⌛', shortcode: 'hourglass' },
  { ico: '⏳', shortcode: 'hourglass_flowing_sand' },
  { ico: '⌚', shortcode: 'watch' },
  { ico: '⏰', shortcode: 'alarm_clock' },
  { ico: '⏱️', shortcode: 'stopwatch' },
  { ico: '⏲️', shortcode: 'timer_clock' },
  { ico: '🕰️', shortcode: 'mantelpiece_clock' },
  { ico: '🕛', shortcode: 'clock12' },
  { ico: '🕧', shortcode: 'clock1230' },
  { ico: '🕐', shortcode: 'clock1' },
  { ico: '🕜', shortcode: 'clock130' },
  { ico: '🕑', shortcode: 'clock2' },
  { ico: '🕝', shortcode: 'clock230' },
  { ico: '🕒', shortcode: 'clock3' },
  { ico: '🕞', shortcode: 'clock330' },
  { ico: '🕓', shortcode: 'clock4' },
  { ico: '🕟', shortcode: 'clock430' },
  { ico: '🕔', shortcode: 'clock5' },
  { ico: '🕠', shortcode: 'clock530' },
  { ico: '🕕', shortcode: 'clock6' },
  { ico: '🕡', shortcode: 'clock630' },
  { ico: '🕖', shortcode: 'clock7' },
  { ico: '🕢', shortcode: 'clock730' },
  { ico: '🕗', shortcode: 'clock8' },
  { ico: '🕣', shortcode: 'clock830' },
  { ico: '🕘', shortcode: 'clock9' },
  { ico: '🕤', shortcode: 'clock930' },
  { ico: '🕙', shortcode: 'clock10' },
  { ico: '🕥', shortcode: 'clock1030' },
  { ico: '🕚', shortcode: 'clock11' },
  { ico: '🕦', shortcode: 'clock1130' },
  { ico: '🌑', shortcode: 'new_moon' },
  { ico: '🌒', shortcode: 'waxing_crescent_moon' },
  { ico: '🌓', shortcode: 'first_quarter_moon' },
  { ico: '🌔', shortcode: 'moon' },
  { ico: '🌕', shortcode: 'full_moon' },
  { ico: '🌖', shortcode: 'waning_gibbous_moon' },
  { ico: '🌗', shortcode: 'last_quarter_moon' },
  { ico: '🌘', shortcode: 'waning_crescent_moon' },
  { ico: '🌙', shortcode: 'crescent_moon' },
  { ico: '🌚', shortcode: 'new_moon_with_face' },
  { ico: '🌛', shortcode: 'first_quarter_moon_with_face' },
  { ico: '🌜', shortcode: 'last_quarter_moon_with_face' },
  { ico: '🌡️', shortcode: 'thermometer' },
  { ico: '☀️', shortcode: 'sunny' },
  { ico: '🌝', shortcode: 'full_moon_with_face' },
  { ico: '🌞', shortcode: 'sun_with_face' },
  { ico: '⭐', shortcode: 'star' },
  { ico: '🌟', shortcode: 'star2' },
  { ico: '🌠', shortcode: 'stars' },
  { ico: '🌌', shortcode: 'milky_way' },
  { ico: '☁️', shortcode: 'cloud' },
  { ico: '⛅', shortcode: 'partly_sunny' },
  { ico: '⛈️', shortcode: 'cloud_with_lightning_and_rain' },
  { ico: '🌤️', shortcode: 'sun_behind_small_cloud' },
  { ico: '🌥️', shortcode: 'sun_behind_large_cloud' },
  { ico: '🌦️', shortcode: 'sun_behind_rain_cloud' },
  { ico: '🌧️', shortcode: 'cloud_with_rain' },
  { ico: '🌨️', shortcode: 'cloud_with_snow' },
  { ico: '🌩️', shortcode: 'cloud_with_lightning' },
  { ico: '🌪️', shortcode: 'tornado' },
  { ico: '🌫️', shortcode: 'fog' },
  { ico: '🌬️', shortcode: 'wind_face' },
  { ico: '🌀', shortcode: 'cyclone' },
  { ico: '🌈', shortcode: 'rainbow' },
  { ico: '🌂', shortcode: 'closed_umbrella' },
  { ico: '☂️', shortcode: 'open_umbrella' },
  { ico: '☔', shortcode: 'umbrella' },
  { ico: '⛱️', shortcode: 'parasol_on_ground' },
  { ico: '⚡', shortcode: 'zap' },
  { ico: '❄️', shortcode: 'snowflake' },
  { ico: '☃️', shortcode: 'snowman_with_snow' },
  { ico: '⛄', shortcode: 'snowman' },
  { ico: '☄️', shortcode: 'comet' },
  { ico: '🔥', shortcode: 'fire' },
  { ico: '💧', shortcode: 'droplet' },
  { ico: '🌊', shortcode: 'ocean' },
]

export const EMOJI_LIST_ACTIVITY = [
  { ico: '🎃', shortcode: 'jack_o_lantern' },
  { ico: '🎄', shortcode: 'christmas_tree' },
  { ico: '🎆', shortcode: 'fireworks' },
  { ico: '🎇', shortcode: 'sparkler' },
  { ico: '🧨', shortcode: 'firecracker' },
  { ico: '✨', shortcode: 'sparkles' },
  { ico: '🎈', shortcode: 'balloon' },
  { ico: '🎉', shortcode: 'tada' },
  { ico: '🎊', shortcode: 'confetti_ball' },
  { ico: '🎋', shortcode: 'tanabata_tree' },
  { ico: '🎍', shortcode: 'bamboo' },
  { ico: '🎎', shortcode: 'dolls' },
  { ico: '🎏', shortcode: 'flags' },
  { ico: '🎐', shortcode: 'wind_chime' },
  { ico: '🎑', shortcode: 'rice_scene' },
  { ico: '🧧', shortcode: 'red_envelope' },
  { ico: '🎀', shortcode: 'ribbon' },
  { ico: '🎁', shortcode: 'gift' },
  { ico: '🎗️', shortcode: 'reminder_ribbon' },
  { ico: '🎟️', shortcode: 'tickets' },
  { ico: '🎫', shortcode: 'ticket' },
  { ico: '🎖️', shortcode: 'medal_military' },
  { ico: '🏆', shortcode: 'trophy' },
  { ico: '🏅', shortcode: 'medal_sports' },
  { ico: '🥇', shortcode: '1st_place_medal' },
  { ico: '🥈', shortcode: '2nd_place_medal' },
  { ico: '🥉', shortcode: '3rd_place_medal' },
  { ico: '⚽', shortcode: 'soccer' },
  { ico: '⚾', shortcode: 'baseball' },
  { ico: '🥎', shortcode: 'softball' },
  { ico: '🏀', shortcode: 'basketball' },
  { ico: '🏐', shortcode: 'volleyball' },
  { ico: '🏈', shortcode: 'football' },
  { ico: '🏉', shortcode: 'rugby_football' },
  { ico: '🎾', shortcode: 'tennis' },
  { ico: '🥏', shortcode: 'flying_disc' },
  { ico: '🎳', shortcode: 'bowling' },
  { ico: '🏏', shortcode: 'cricket_game' },
  { ico: '🏑', shortcode: 'field_hockey' },
  { ico: '🏒', shortcode: 'ice_hockey' },
  { ico: '🥍', shortcode: 'lacrosse' },
  { ico: '🏓', shortcode: 'ping_pong' },
  { ico: '🏸', shortcode: 'badminton' },
  { ico: '🥊', shortcode: 'boxing_glove' },
  { ico: '🥋', shortcode: 'martial_arts_uniform' },
  { ico: '🥅', shortcode: 'goal_net' },
  { ico: '⛳', shortcode: 'golf' },
  { ico: '⛸️', shortcode: 'ice_skate' },
  { ico: '🎣', shortcode: 'fishing_pole_and_fish' },
  { ico: '🎽', shortcode: 'running_shirt_with_sash' },
  { ico: '🎿', shortcode: 'ski' },
  { ico: '🛷', shortcode: 'sled' },
  { ico: '🥌', shortcode: 'curling_stone' },
  { ico: '🎯', shortcode: 'dart' },
  { ico: '🔫', shortcode: 'gun' },
  { ico: '🎱', shortcode: '8ball' },
  { ico: '🔮', shortcode: 'crystal_ball' },
  { ico: '🎮', shortcode: 'video_game' },
  { ico: '🕹️', shortcode: 'joystick' },
  { ico: '🎰', shortcode: 'slot_machine' },
  { ico: '🎲', shortcode: 'game_die' },
  { ico: '🧩', shortcode: 'jigsaw' },
  { ico: '🧸', shortcode: 'teddy_bear' },
  { ico: '♟️', shortcode: 'chess_pawn' },
  { ico: '🃏', shortcode: 'black_joker' },
  { ico: '🀄', shortcode: 'mahjong' },
  { ico: '🎴', shortcode: 'flower_playing_cards' },
  { ico: '🎭', shortcode: 'performing_arts' },
  { ico: '🖼️', shortcode: 'framed_picture' },
  { ico: '🎨', shortcode: 'art' },
  { ico: '🧵', shortcode: 'thread' },
  { ico: '🧶', shortcode: 'yarn' },
]

export const EMOJI_LIST_OBJECT = [
  { ico: '👓', shortcode: 'eyeglasses' },
  { ico: '🕶️', shortcode: 'dark_sunglasses' },
  { ico: '🥽', shortcode: 'goggles' },
  { ico: '🥼', shortcode: 'lab_coat' },
  { ico: '👔', shortcode: 'necktie' },
  { ico: '👕', shortcode: 'shirt' },
  { ico: '👖', shortcode: 'jeans' },
  { ico: '🧣', shortcode: 'scarf' },
  { ico: '🧤', shortcode: 'gloves' },
  { ico: '🧥', shortcode: 'coat' },
  { ico: '🧦', shortcode: 'socks' },
  { ico: '👗', shortcode: 'dress' },
  { ico: '👘', shortcode: 'kimono' },
  { ico: '👙', shortcode: 'bikini' },
  { ico: '👚', shortcode: 'womans_clothes' },
  { ico: '👛', shortcode: 'purse' },
  { ico: '👜', shortcode: 'handbag' },
  { ico: '👝', shortcode: 'pouch' },
  { ico: '🛍️', shortcode: 'shopping' },
  { ico: '🎒', shortcode: 'school_satchel' },
  { ico: '👞', shortcode: 'mans_shoe' },
  { ico: '👟', shortcode: 'athletic_shoe' },
  { ico: '🥾', shortcode: 'hiking_boot' },
  { ico: '🥿', shortcode: 'flat_shoe' },
  { ico: '👠', shortcode: 'high_heel' },
  { ico: '👡', shortcode: 'sandal' },
  { ico: '👢', shortcode: 'boot' },
  { ico: '👑', shortcode: 'crown' },
  { ico: '👒', shortcode: 'womans_hat' },
  { ico: '🎩', shortcode: 'tophat' },
  { ico: '🎓', shortcode: 'mortar_board' },
  { ico: '🧢', shortcode: 'billed_cap' },
  { ico: '⛑️', shortcode: 'rescue_worker_helmet' },
  { ico: '📿', shortcode: 'prayer_beads' },
  { ico: '💄', shortcode: 'lipstick' },
  { ico: '💍', shortcode: 'ring' },
  { ico: '💎', shortcode: 'gem' },
  { ico: '🔇', shortcode: 'mute' },
  { ico: '🔈', shortcode: 'speaker' },
  { ico: '🔉', shortcode: 'sound' },
  { ico: '🔊', shortcode: 'loud_sound' },
  { ico: '📢', shortcode: 'loudspeaker' },
  { ico: '📣', shortcode: 'mega' },
  { ico: '📯', shortcode: 'postal_horn' },
  { ico: '🔔', shortcode: 'bell' },
  { ico: '🔕', shortcode: 'no_bell' },
  { ico: '🎼', shortcode: 'musical_score' },
  { ico: '🎙️', shortcode: 'studio_microphone' },
  { ico: '🎚️', shortcode: 'level_slider' },
  { ico: '🎛️', shortcode: 'control_knobs' },
  { ico: '🎤', shortcode: 'microphone' },
  { ico: '🎧', shortcode: 'headphones' },
  { ico: '📻', shortcode: 'radio' },
  { ico: '🎷', shortcode: 'saxophone' },
  { ico: '🎸', shortcode: 'guitar' },
  { ico: '🎹', shortcode: 'musical_keyboard' },
  { ico: '🎺', shortcode: 'trumpet' },
  { ico: '🎻', shortcode: 'violin' },
  { ico: '🥁', shortcode: 'drum' },
  { ico: '📱', shortcode: 'iphone' },
  { ico: '📲', shortcode: 'calling' },
  { ico: '☎️', shortcode: 'phone' },
  { ico: '📞', shortcode: 'telephone_receiver' },
  { ico: '📟', shortcode: 'pager' },
  { ico: '📠', shortcode: 'fax' },
  { ico: '🔋', shortcode: 'battery' },
  { ico: '🔌', shortcode: 'electric_plug' },
  { ico: '💻', shortcode: 'computer' },
  { ico: '🖥️', shortcode: 'desktop_computer' },
  { ico: '🖨️', shortcode: 'printer' },
  { ico: '⌨️', shortcode: 'keyboard' },
  { ico: '🖱️', shortcode: 'computer_mouse' },
  { ico: '🖲️', shortcode: 'trackball' },
  { ico: '💽', shortcode: 'minidisc' },
  { ico: '💾', shortcode: 'floppy_disk' },
  { ico: '💿', shortcode: 'cd' },
  { ico: '📀', shortcode: 'dvd' },
  { ico: '🧮', shortcode: 'abacus' },
  { ico: '🎥', shortcode: 'movie_camera' },
  { ico: '🎞️', shortcode: 'film_strip' },
  { ico: '📽️', shortcode: 'film_projector' },
  { ico: '🎬', shortcode: 'clapper' },
  { ico: '📺', shortcode: 'tv' },
  { ico: '📷', shortcode: 'camera' },
  { ico: '📸', shortcode: 'camera_flash' },
  { ico: '📹', shortcode: 'video_camera' },
  { ico: '📼', shortcode: 'vhs' },
  { ico: '🔍', shortcode: 'mag' },
  { ico: '🔎', shortcode: 'mag_right' },
  { ico: '🕯️', shortcode: 'candle' },
  { ico: '💡', shortcode: 'bulb' },
  { ico: '🔦', shortcode: 'flashlight' },
  { ico: '🏮', shortcode: 'izakaya_lantern' },
  { ico: '📔', shortcode: 'notebook_with_decorative_cover' },
  { ico: '📕', shortcode: 'closed_book' },
  { ico: '📖', shortcode: 'book' },
  { ico: '📗', shortcode: 'green_book' },
  { ico: '📘', shortcode: 'blue_book' },
  { ico: '📙', shortcode: 'orange_book' },
  { ico: '📚', shortcode: 'books' },
  { ico: '📓', shortcode: 'notebook' },
  { ico: '📒', shortcode: 'ledger' },
  { ico: '📃', shortcode: 'page_with_curl' },
  { ico: '📜', shortcode: 'scroll' },
  { ico: '📄', shortcode: 'page_facing_up' },
  { ico: '📰', shortcode: 'newspaper' },
  { ico: '🗞️', shortcode: 'newspaper_roll' },
  { ico: '📑', shortcode: 'bookmark_tabs' },
  { ico: '🔖', shortcode: 'bookmark' },
  { ico: '🏷️', shortcode: 'label' },
  { ico: '💰', shortcode: 'moneybag' },
  { ico: '💴', shortcode: 'yen' },
  { ico: '💵', shortcode: 'dollar' },
  { ico: '💶', shortcode: 'euro' },
  { ico: '💷', shortcode: 'pound' },
  { ico: '💸', shortcode: 'money_with_wings' },
  { ico: '💳', shortcode: 'credit_card' },
  { ico: '🧾', shortcode: 'receipt' },
  { ico: '💹', shortcode: 'chart' },
  { ico: '✉️', shortcode: 'envelope' },
  { ico: '📧', shortcode: 'e-mail' },
  { ico: '📨', shortcode: 'incoming_envelope' },
  { ico: '📩', shortcode: 'envelope_with_arrow' },
  { ico: '📤', shortcode: 'outbox_tray' },
  { ico: '📥', shortcode: 'inbox_tray' },
  { ico: '📦', shortcode: 'package' },
  { ico: '📫', shortcode: 'mailbox' },
  { ico: '📪', shortcode: 'mailbox_closed' },
  { ico: '📬', shortcode: 'mailbox_with_mail' },
  { ico: '📭', shortcode: 'mailbox_with_no_mail' },
  { ico: '📮', shortcode: 'postbox' },
  { ico: '🗳️', shortcode: 'ballot_box' },
  { ico: '✏️', shortcode: 'pencil2' },
  { ico: '✒️', shortcode: 'black_nib' },
  { ico: '🖋️', shortcode: 'fountain_pen' },
  { ico: '🖊️', shortcode: 'pen' },
  { ico: '🖌️', shortcode: 'paintbrush' },
  { ico: '🖍️', shortcode: 'crayon' },
  { ico: '📝', shortcode: 'memo' },
  { ico: '💼', shortcode: 'briefcase' },
  { ico: '📁', shortcode: 'file_folder' },
  { ico: '📂', shortcode: 'open_file_folder' },
  { ico: '🗂️', shortcode: 'card_index_dividers' },
  { ico: '📅', shortcode: 'date' },
  { ico: '📆', shortcode: 'calendar' },
  { ico: '🗒️', shortcode: 'spiral_notepad' },
  { ico: '🗓️', shortcode: 'spiral_calendar' },
  { ico: '📇', shortcode: 'card_index' },
  { ico: '📈', shortcode: 'chart_with_upwards_trend' },
  { ico: '📉', shortcode: 'chart_with_downwards_trend' },
  { ico: '📊', shortcode: 'bar_chart' },
  { ico: '📋', shortcode: 'clipboard' },
  { ico: '📌', shortcode: 'pushpin' },
  { ico: '📍', shortcode: 'round_pushpin' },
  { ico: '📎', shortcode: 'paperclip' },
  { ico: '🖇️', shortcode: 'paperclips' },
  { ico: '📏', shortcode: 'straight_ruler' },
  { ico: '📐', shortcode: 'triangular_ruler' },
  { ico: '✂️', shortcode: 'scissors' },
  { ico: '🗃️', shortcode: 'card_file_box' },
  { ico: '🗄️', shortcode: 'file_cabinet' },
  { ico: '🗑️', shortcode: 'wastebasket' },
  { ico: '🔓', shortcode: 'unlock' },
  { ico: '🔏', shortcode: 'lock_with_ink_pen' },
  { ico: '🔐', shortcode: 'closed_lock_with_key' },
  { ico: '🔑', shortcode: 'key' },
  { ico: '🗝️', shortcode: 'old_key' },
  { ico: '🔨', shortcode: 'hammer' },
  { ico: '⛏️', shortcode: 'pick' },
  { ico: '⚒️', shortcode: 'hammer_and_pick' },
  { ico: '🛠️', shortcode: 'hammer_and_wrench' },
  { ico: '🗡️', shortcode: 'dagger' },
  { ico: '⚔️', shortcode: 'crossed_swords' },
  { ico: '💣', shortcode: 'bomb' },
  { ico: '🏹', shortcode: 'bow_and_arrow' },
  { ico: '🛡️', shortcode: 'shield' },
  { ico: '🔧', shortcode: 'wrench' },
  { ico: '🔩', shortcode: 'nut_and_bolt' },
  { ico: '⚙️', shortcode: 'gear' },
  { ico: '🗜️', shortcode: 'clamp' },
  { ico: '⚖️', shortcode: 'balance_scale' },
  { ico: '🔗', shortcode: 'link' },
  { ico: '⛓️', shortcode: 'chains' },
  { ico: '🧰', shortcode: 'toolbox' },
  { ico: '🧲', shortcode: 'magnet' },
  { ico: '⚗️', shortcode: 'alembic' },
  { ico: '🧪', shortcode: 'test_tube' },
  { ico: '🧫', shortcode: 'petri_dish' },
  { ico: '🧬', shortcode: 'dna' },
  { ico: '🔬', shortcode: 'microscope' },
  { ico: '🔭', shortcode: 'telescope' },
  { ico: '📡', shortcode: 'satellite' },
  { ico: '💉', shortcode: 'syringe' },
  { ico: '💊', shortcode: 'pill' },
  { ico: '🚪', shortcode: 'door' },
  { ico: '🛏️', shortcode: 'bed' },
  { ico: '🛋️', shortcode: 'couch_and_lamp' },
  { ico: '🚽', shortcode: 'toilet' },
  { ico: '🚿', shortcode: 'shower' },
  { ico: '🛁', shortcode: 'bathtub' },
  { ico: '🧴', shortcode: 'lotion_bottle' },
  { ico: '🧷', shortcode: 'safety_pin' },
  { ico: '🧹', shortcode: 'broom' },
  { ico: '🧺', shortcode: 'basket' },
  { ico: '🧻', shortcode: 'roll_of_paper' },
  { ico: '🧼', shortcode: 'soap' },
  { ico: '🧽', shortcode: 'sponge' },
  { ico: '🧯', shortcode: 'fire_extinguisher' },
  { ico: '🛒', shortcode: 'shopping_cart' },
  { ico: '🚬', shortcode: 'smoking' },
  { ico: '⚰️', shortcode: 'coffin' },
  { ico: '⚱️', shortcode: 'funeral_urn' },
  { ico: '🧿', shortcode: 'nazar_amulet' },
  { ico: '🗿', shortcode: 'moyai' },
  { ico: '🏧', shortcode: 'atm' },
]

export const EMOJI_LIST_SYMBOL = [
  { ico: '1️⃣', shortcode: 'one' },
  { ico: '2️⃣', shortcode: 'two' },
  { ico: '3️⃣', shortcode: 'three' },
  { ico: '4️⃣', shortcode: 'four' },
  { ico: '5️⃣', shortcode: 'five' },
  { ico: '6️⃣', shortcode: 'six' },
  { ico: '7️⃣', shortcode: 'seven' },
  { ico: '8️⃣', shortcode: 'eight' },
  { ico: '9️⃣', shortcode: 'nine' },
  { ico: '🔟', shortcode: 'keycap_ten' },
  { ico: '🔠', shortcode: 'capital_abcd' },
  { ico: '🔡', shortcode: 'abcd' },
  { ico: '🔢', shortcode: '1234' },
  { ico: '🔣', shortcode: 'symbols' },
  { ico: '🔤', shortcode: 'abc' },
  { ico: '🅰️', shortcode: 'a' },
  { ico: '🆎', shortcode: 'ab' },
  { ico: '🅱️', shortcode: 'b' },
  { ico: '🆑', shortcode: 'cl' },
  { ico: '🆒', shortcode: 'cool' },
  { ico: '🆓', shortcode: 'free' },
  { ico: 'ℹ️', shortcode: 'information_source' },
  { ico: '🆔', shortcode: 'id' },
  { ico: 'Ⓜ️', shortcode: 'm' },
  { ico: '🆕', shortcode: 'new' },
  { ico: '🆖', shortcode: 'ng' },
  { ico: '🅾️', shortcode: 'o2' },
  { ico: '🆗', shortcode: 'ok' },
  { ico: '🅿️', shortcode: 'parking' },
  { ico: '🆘', shortcode: 'sos' },
  { ico: '🆙', shortcode: 'up' },
  { ico: '🆚', shortcode: 'vs' },
  { ico: '🈁', shortcode: 'koko' },
  { ico: '🈂️', shortcode: 'sa' },
  { ico: '🈷️', shortcode: 'u6708' },
  { ico: '🈶', shortcode: 'u6709' },
  { ico: '🈯', shortcode: 'u6307' },
  { ico: '🉐', shortcode: 'ideograph_advantage' },
  { ico: '🈹', shortcode: 'u5272' },
  { ico: '🈚', shortcode: 'u7121' },
  { ico: '🈲', shortcode: 'u7981' },
  { ico: '🉑', shortcode: 'accept' },
  { ico: '🈸', shortcode: 'u7533' },
  { ico: '🈴', shortcode: 'u5408' },
  { ico: '🈳', shortcode: 'u7a7a' },
  { ico: '㊗️', shortcode: 'congratulations' },
  { ico: '㊙️', shortcode: 'secret' },
  { ico: '🈺', shortcode: 'u55b6' },
  { ico: '🈵', shortcode: 'u6e80' },
  { ico: '🚮', shortcode: 'put_litter_in_its_place' },
  { ico: '🚰', shortcode: 'potable_water' },
  { ico: '♿', shortcode: 'wheelchair' },
  { ico: '🚹', shortcode: 'mens' },
  { ico: '🚺', shortcode: 'womens' },
  { ico: '🚻', shortcode: 'restroom' },
  { ico: '🚼', shortcode: 'baby_symbol' },
  { ico: '🚾', shortcode: 'wc' },
  { ico: '🛂', shortcode: 'passport_control' },
  { ico: '🛃', shortcode: 'customs' },
  { ico: '🛄', shortcode: 'baggage_claim' },
  { ico: '🛅', shortcode: 'left_luggage' },
  { ico: '⚠️', shortcode: 'warning' },
  { ico: '🚸', shortcode: 'children_crossing' },
  { ico: '⛔', shortcode: 'no_entry' },
  { ico: '🚫', shortcode: 'no_entry_sign' },
  { ico: '🚳', shortcode: 'no_bicycles' },
  { ico: '🚭', shortcode: 'no_smoking' },
  { ico: '🚯', shortcode: 'do_not_litter' },
  { ico: '🚱', shortcode: 'non-potable_water' },
  { ico: '🚷', shortcode: 'no_pedestrians' },
  { ico: '📵', shortcode: 'no_mobile_phones' },
  { ico: '🔞', shortcode: 'underage' },
  { ico: '☢️', shortcode: 'radioactive' },
  { ico: '☣️', shortcode: 'biohazard' },
  { ico: '⬆️', shortcode: 'arrow_up' },
  { ico: '➡️', shortcode: 'arrow_right' },
  { ico: '⬇️', shortcode: 'arrow_down' },
  { ico: '⬅️', shortcode: 'arrow_left' },
  { ico: '↩️', shortcode: 'leftwards_arrow_with_hook' },
  { ico: '↪️', shortcode: 'arrow_right_hook' },
  { ico: '⤴️', shortcode: 'arrow_heading_up' },
  { ico: '⤵️', shortcode: 'arrow_heading_down' },
  { ico: '🔃', shortcode: 'arrows_clockwise' },
  { ico: '🔄', shortcode: 'arrows_counterclockwise' },
  { ico: '🔙', shortcode: 'back' },
  { ico: '🔚', shortcode: 'end' },
  { ico: '🔛', shortcode: 'on' },
  { ico: '🔜', shortcode: 'soon' },
  { ico: '🔝', shortcode: 'top' },
  { ico: '🛐', shortcode: 'place_of_worship' },
  { ico: '⚛️', shortcode: 'atom_symbol' },
  { ico: '🕉️', shortcode: 'om' },
  { ico: '✡️', shortcode: 'star_of_david' },
  { ico: '☸️', shortcode: 'wheel_of_dharma' },
  { ico: '☯️', shortcode: 'yin_yang' },
  { ico: '✝️', shortcode: 'latin_cross' },
  { ico: '☦️', shortcode: 'orthodox_cross' },
  { ico: '☪️', shortcode: 'star_and_crescent' },
  { ico: '☮️', shortcode: 'peace_symbol' },
  { ico: '🕎', shortcode: 'menorah' },
  { ico: '🔯', shortcode: 'six_pointed_star' },
  { ico: '♈', shortcode: 'aries' },
  { ico: '♉', shortcode: 'taurus' },
  { ico: '♊', shortcode: 'gemini' },
  { ico: '♋', shortcode: 'cancer' },
  { ico: '♌', shortcode: 'leo' },
  { ico: '♍', shortcode: 'virgo' },
  { ico: '♎', shortcode: 'libra' },
  { ico: '♏', shortcode: 'scorpius' },
  { ico: '♐', shortcode: 'sagittarius' },
  { ico: '♑', shortcode: 'capricorn' },
  { ico: '♒', shortcode: 'aquarius' },
  { ico: '♓', shortcode: 'pisces' },
  { ico: '⛎', shortcode: 'ophiuchus' },
  { ico: '🔀', shortcode: 'twisted_rightwards_arrows' },
  { ico: '🔁', shortcode: 'repeat' },
  { ico: '🔂', shortcode: 'repeat_one' },
  { ico: '⏩', shortcode: 'fast_forward' },
  { ico: '⏭️', shortcode: 'next_track_button' },
  { ico: '⏯️', shortcode: 'play_or_pause_button' },
  { ico: '⏪', shortcode: 'rewind' },
  { ico: '⏮️', shortcode: 'previous_track_button' },
  { ico: '⏫', shortcode: 'arrow_double_up' },
  { ico: '⏬', shortcode: 'arrow_double_down' },
  { ico: '⏸️', shortcode: 'pause_button' },
  { ico: '⏹️', shortcode: 'stop_button' },
  { ico: '⏺️', shortcode: 'record_button' },
  { ico: '⏏️', shortcode: 'eject_button' },
  { ico: '🎦', shortcode: 'cinema' },
  { ico: '🏁', shortcode: 'checkered_flag' },
  { ico: '🚩', shortcode: 'triangular_flag_on_post' },
  { ico: '🎌', shortcode: 'crossed_flags' },
  { ico: '🏴', shortcode: 'black_flag' },
  { ico: '🏳️', shortcode: 'white_flag' },
  { ico: '🏳️‍🌈', shortcode: 'rainbow_flag' },
  { ico: '🏴‍☠️', shortcode: 'pirate_flag' },
  { ico: '🔴', shortcode: 'red_circle' },
  { ico: '🔵', shortcode: 'large_blue_circle' },
  { ico: '⚫', shortcode: 'black_circle' },
  { ico: '⚪', shortcode: 'white_circle' },
  { ico: '⬛', shortcode: 'black_large_square' },
  { ico: '⬜', shortcode: 'white_large_square' },
  { ico: '◼️', shortcode: 'black_medium_square' },
  { ico: '◻️', shortcode: 'white_medium_square' },
  { ico: '◾', shortcode: 'black_medium_small_square' },
  { ico: '◽', shortcode: 'white_medium_small_square' },
  { ico: '🔶', shortcode: 'large_orange_diamond' },
  { ico: '🔷', shortcode: 'large_blue_diamond' },
  { ico: '🔸', shortcode: 'small_orange_diamond' },
  { ico: '🔹', shortcode: 'small_blue_diamond' },
  { ico: '🔺', shortcode: 'small_red_triangle' },
  { ico: '🔻', shortcode: 'small_red_triangle_down' },
  { ico: '💠', shortcode: 'diamond_shape_with_a_dot_inside' },
  { ico: '🔘', shortcode: 'radio_button' },
  { ico: '🔳', shortcode: 'white_square_button' },
  { ico: '🔲', shortcode: 'black_square_button' },
  { ico: '🔅', shortcode: 'low_brightness' },
  { ico: '🔆', shortcode: 'high_brightness' },
  { ico: '📶', shortcode: 'signal_strength' },
  { ico: '📳', shortcode: 'vibration_mode' },
  { ico: '📴', shortcode: 'mobile_phone_off' },
  { ico: '✖️', shortcode: 'heavy_multiplication_x' },
  { ico: '➕', shortcode: 'heavy_plus_sign' },
  { ico: '➖', shortcode: 'heavy_minus_sign' },
  { ico: '➗', shortcode: 'heavy_division_sign' },
  { ico: '♾️', shortcode: 'infinity' },
  { ico: '❓', shortcode: 'question' },
  { ico: '❔', shortcode: 'grey_question' },
  { ico: '❕', shortcode: 'grey_exclamation' },
  { ico: '❗', shortcode: 'exclamation' },
  { ico: '⚕️', shortcode: 'medical_symbol' },
  { ico: '♻️', shortcode: 'recycle' },
  { ico: '⚜️', shortcode: 'fleur_de_lis' },
  { ico: '🔱', shortcode: 'trident' },
  { ico: '📛', shortcode: 'name_badge' },
  { ico: '🔰', shortcode: 'beginner' },
  { ico: '⭕', shortcode: 'o' },
  { ico: '✅', shortcode: 'white_check_mark' },
  { ico: '✔️', shortcode: 'heavy_check_mark' },
  { ico: '❌', shortcode: 'x' },
  { ico: '❎', shortcode: 'negative_squared_cross_mark' },
  { ico: '➰', shortcode: 'curly_loop' },
  { ico: '➿', shortcode: 'loop' },
  { ico: '✳️', shortcode: 'eight_spoked_asterisk' },
  { ico: '✴️', shortcode: 'eight_pointed_black_star' },
  { ico: '❇️', shortcode: 'sparkle' },
]

export const ABBR_LIST_SYMBOL = [
  { ico: '🇦🇨', shortcode: 'ascension_island' },
  { ico: '🇦🇩', shortcode: 'andorra' },
  { ico: '🇦🇪', shortcode: 'united_arab_emirates' },
  { ico: '🇦🇫', shortcode: 'afghanistan' },
  { ico: '🇦🇬', shortcode: 'antigua_barbuda' },
  { ico: '🇦🇮', shortcode: 'anguilla' },
  { ico: '🇦🇱', shortcode: 'albania' },
  { ico: '🇦🇲', shortcode: 'armenia' },
  { ico: '🇦🇴', shortcode: 'angola' },
  { ico: '🇦🇶', shortcode: 'antarctica' },
  { ico: '🇦🇷', shortcode: 'argentina' },
  { ico: '🇦🇸', shortcode: 'american_samoa' },
  { ico: '🇦🇹', shortcode: 'austria' },
  { ico: '🇦🇺', shortcode: 'australia' },
  { ico: '🇦🇼', shortcode: 'aruba' },
  { ico: '🇦🇽', shortcode: 'aland_islands' },
  { ico: '🇦🇿', shortcode: 'azerbaijan' },
  { ico: '🇧🇦', shortcode: 'bosnia_herzegovina' },
  { ico: '🇧🇧', shortcode: 'barbados' },
  { ico: '🇧🇩', shortcode: 'bangladesh' },
  { ico: '🇧🇪', shortcode: 'belgium' },
  { ico: '🇧🇫', shortcode: 'burkina_faso' },
  { ico: '🇧🇬', shortcode: 'bulgaria' },
  { ico: '🇧🇭', shortcode: 'bahrain' },
  { ico: '🇧🇮', shortcode: 'burundi' },
  { ico: '🇧🇯', shortcode: 'benin' },
  { ico: '🇧🇱', shortcode: 'st_barthelemy' },
  { ico: '🇧🇲', shortcode: 'bermuda' },
  { ico: '🇧🇳', shortcode: 'brunei' },
  { ico: '🇧🇴', shortcode: 'bolivia' },
  { ico: '🇧🇶', shortcode: 'caribbean_netherlands' },
  { ico: '🇧🇷', shortcode: 'brazil' },
  { ico: '🇧🇸', shortcode: 'bahamas' },
  { ico: '🇧🇹', shortcode: 'bhutan' },
  { ico: '🇧🇻', shortcode: 'bouvet_island' },
  { ico: '🇧🇼', shortcode: 'botswana' },
  { ico: '🇧🇾', shortcode: 'belarus' },
  { ico: '🇧🇿', shortcode: 'belize' },
  { ico: '🇨🇦', shortcode: 'canada' },
  { ico: '🇨🇨', shortcode: 'cocos_islands' },
  { ico: '🇨🇩', shortcode: 'congo_kinshasa' },
  { ico: '🇨🇫', shortcode: 'central_african_republic' },
  { ico: '🇨🇬', shortcode: 'congo_brazzaville' },
  { ico: '🇨🇭', shortcode: 'switzerland' },
  { ico: '🇨🇮', shortcode: 'cote_divoire' },
  { ico: '🇨🇰', shortcode: 'cook_islands' },
  { ico: '🇨🇱', shortcode: 'chile' },
  { ico: '🇨🇲', shortcode: 'cameroon' },
  { ico: '🇨🇳', shortcode: 'cn' },
  { ico: '🇨🇴', shortcode: 'colombia' },
  { ico: '🇨🇵', shortcode: 'clipperton_island' },
  { ico: '🇨🇷', shortcode: 'costa_rica' },
  { ico: '🇨🇺', shortcode: 'cuba' },
  { ico: '🇨🇻', shortcode: 'cape_verde' },
  { ico: '🇨🇼', shortcode: 'curacao' },
  { ico: '🇨🇽', shortcode: 'christmas_island' },
  { ico: '🇨🇾', shortcode: 'cyprus' },
  { ico: '🇨🇿', shortcode: 'czech_republic' },
  { ico: '🇩🇪', shortcode: 'de' },
  { ico: '🇩🇬', shortcode: 'diego_garcia' },
  { ico: '🇩🇯', shortcode: 'djibouti' },
  { ico: '🇩🇰', shortcode: 'denmark' },
  { ico: '🇩🇲', shortcode: 'dominica' },
  { ico: '🇩🇴', shortcode: 'dominican_republic' },
  { ico: '🇩🇿', shortcode: 'algeria' },
  { ico: '🇪🇦', shortcode: 'ceuta_melilla' },
  { ico: '🇪🇨', shortcode: 'ecuador' },
  { ico: '🇪🇪', shortcode: 'estonia' },
  { ico: '🇪🇬', shortcode: 'egypt' },
  { ico: '🇪🇭', shortcode: 'western_sahara' },
  { ico: '🇪🇷', shortcode: 'eritrea' },
  { ico: '🇪🇸', shortcode: 'es' },
  { ico: '🇪🇹', shortcode: 'ethiopia' },
  { ico: '🇪🇺', shortcode: 'eu' },
  { ico: '🇫🇮', shortcode: 'finland' },
  { ico: '🇫🇯', shortcode: 'fiji' },
  { ico: '🇫🇰', shortcode: 'falkland_islands' },
  { ico: '🇫🇲', shortcode: 'micronesia' },
  { ico: '🇫🇴', shortcode: 'faroe_islands' },
  { ico: '🇫🇷', shortcode: 'fr' },
  { ico: '🇬🇦', shortcode: 'gabon' },
  { ico: '🇬🇧', shortcode: 'gb' },
  { ico: '🇬🇩', shortcode: 'grenada' },
  { ico: '🇬🇪', shortcode: 'georgia' },
  { ico: '🇬🇫', shortcode: 'french_guiana' },
  { ico: '🇬🇬', shortcode: 'guernsey' },
  { ico: '🇬🇭', shortcode: 'ghana' },
  { ico: '🇬🇮', shortcode: 'gibraltar' },
  { ico: '🇬🇱', shortcode: 'greenland' },
  { ico: '🇬🇲', shortcode: 'gambia' },
  { ico: '🇬🇳', shortcode: 'guinea' },
  { ico: '🇬🇵', shortcode: 'guadeloupe' },
  { ico: '🇬🇶', shortcode: 'equatorial_guinea' },
  { ico: '🇬🇷', shortcode: 'greece' },
  { ico: '🇬🇸', shortcode: 'south_georgia_south_sandwich_islands' },
  { ico: '🇬🇹', shortcode: 'guatemala' },
  { ico: '🇬🇺', shortcode: 'guam' },
  { ico: '🇬🇼', shortcode: 'guinea_bissau' },
  { ico: '🇬🇾', shortcode: 'guyana' },
  { ico: '🇭🇰', shortcode: 'hong_kong' },
  { ico: '🇭🇲', shortcode: 'heard_mcdonald_islands' },
  { ico: '🇭🇳', shortcode: 'honduras' },
  { ico: '🇭🇷', shortcode: 'croatia' },
  { ico: '🇭🇹', shortcode: 'haiti' },
  { ico: '🇭🇺', shortcode: 'hungary' },
  { ico: '🇮🇨', shortcode: 'canary_islands' },
  { ico: '🇮🇩', shortcode: 'indonesia' },
  { ico: '🇮🇪', shortcode: 'ireland' },
  { ico: '🇮🇱', shortcode: 'israel' },
  { ico: '🇮🇲', shortcode: 'isle_of_man' },
  { ico: '🇮🇳', shortcode: 'india' },
  { ico: '🇮🇴', shortcode: 'british_indian_ocean_territory' },
  { ico: '🇮🇶', shortcode: 'iraq' },
  { ico: '🇮🇷', shortcode: 'iran' },
  { ico: '🇮🇸', shortcode: 'iceland' },
  { ico: '🇮🇹', shortcode: 'it' },
  { ico: '🇯🇪', shortcode: 'jersey' },
  { ico: '🇯🇲', shortcode: 'jamaica' },
  { ico: '🇯🇴', shortcode: 'jordan' },
  { ico: '🇯🇵', shortcode: 'jp' },
  { ico: '🇰🇪', shortcode: 'kenya' },
  { ico: '🇰🇬', shortcode: 'kyrgyzstan' },
  { ico: '🇰🇭', shortcode: 'cambodia' },
  { ico: '🇰🇮', shortcode: 'kiribati' },
  { ico: '🇰🇲', shortcode: 'comoros' },
  { ico: '🇰🇳', shortcode: 'st_kitts_nevis' },
  { ico: '🇰🇵', shortcode: 'north_korea' },
  { ico: '🇰🇷', shortcode: 'kr' },
  { ico: '🇰🇼', shortcode: 'kuwait' },
  { ico: '🇰🇾', shortcode: 'cayman_islands' },
  { ico: '🇰🇿', shortcode: 'kazakhstan' },
  { ico: '🇱🇦', shortcode: 'laos' },
  { ico: '🇱🇧', shortcode: 'lebanon' },
  { ico: '🇱🇨', shortcode: 'st_lucia' },
  { ico: '🇱🇮', shortcode: 'liechtenstein' },
  { ico: '🇱🇰', shortcode: 'sri_lanka' },
  { ico: '🇱🇷', shortcode: 'liberia' },
  { ico: '🇱🇸', shortcode: 'lesotho' },
  { ico: '🇱🇹', shortcode: 'lithuania' },
  { ico: '🇱🇺', shortcode: 'luxembourg' },
  { ico: '🇱🇻', shortcode: 'latvia' },
  { ico: '🇱🇾', shortcode: 'libya' },
  { ico: '🇲🇦', shortcode: 'morocco' },
  { ico: '🇲🇨', shortcode: 'monaco' },
  { ico: '🇲🇩', shortcode: 'moldova' },
  { ico: '🇲🇪', shortcode: 'montenegro' },
  { ico: '🇲🇫', shortcode: 'st_martin' },
  { ico: '🇲🇬', shortcode: 'madagascar' },
  { ico: '🇲🇭', shortcode: 'marshall_islands' },
  { ico: '🇲🇰', shortcode: 'macedonia' },
  { ico: '🇲🇱', shortcode: 'mali' },
  { ico: '🇲🇲', shortcode: 'myanmar' },
  { ico: '🇲🇳', shortcode: 'mongolia' },
  { ico: '🇲🇴', shortcode: 'macau' },
  { ico: '🇲🇵', shortcode: 'northern_mariana_islands' },
  { ico: '🇲🇶', shortcode: 'martinique' },
  { ico: '🇲🇷', shortcode: 'mauritania' },
  { ico: '🇲🇸', shortcode: 'montserrat' },
  { ico: '🇲🇹', shortcode: 'malta' },
  { ico: '🇲🇺', shortcode: 'mauritius' },
  { ico: '🇲🇻', shortcode: 'maldives' },
  { ico: '🇲🇼', shortcode: 'malawi' },
  { ico: '🇲🇽', shortcode: 'mexico' },
  { ico: '🇲🇾', shortcode: 'malaysia' },
  { ico: '🇲🇿', shortcode: 'mozambique' },
  { ico: '🇳🇦', shortcode: 'namibia' },
  { ico: '🇳🇨', shortcode: 'new_caledonia' },
  { ico: '🇳🇪', shortcode: 'niger' },
  { ico: '🇳🇫', shortcode: 'norfolk_island' },
  { ico: '🇳🇬', shortcode: 'nigeria' },
  { ico: '🇳🇮', shortcode: 'nicaragua' },
  { ico: '🇳🇱', shortcode: 'netherlands' },
  { ico: '🇳🇴', shortcode: 'norway' },
  { ico: '🇳🇵', shortcode: 'nepal' },
  { ico: '🇳🇷', shortcode: 'nauru' },
  { ico: '🇳🇺', shortcode: 'niue' },
  { ico: '🇳🇿', shortcode: 'new_zealand' },
  { ico: '🇴🇲', shortcode: 'oman' },
  { ico: '🇵🇦', shortcode: 'panama' },
  { ico: '🇵🇪', shortcode: 'peru' },
  { ico: '🇵🇫', shortcode: 'french_polynesia' },
  { ico: '🇵🇬', shortcode: 'papua_new_guinea' },
  { ico: '🇵🇭', shortcode: 'philippines' },
  { ico: '🇵🇰', shortcode: 'pakistan' },
  { ico: '🇵🇱', shortcode: 'poland' },
  { ico: '🇵🇲', shortcode: 'st_pierre_miquelon' },
  { ico: '🇵🇳', shortcode: 'pitcairn_islands' },
  { ico: '🇵🇷', shortcode: 'puerto_rico' },
  { ico: '🇵🇸', shortcode: 'palestinian_territories' },
  { ico: '🇵🇹', shortcode: 'portugal' },
  { ico: '🇵🇼', shortcode: 'palau' },
  { ico: '🇵🇾', shortcode: 'paraguay' },
  { ico: '🇶🇦', shortcode: 'qatar' },
  { ico: '🇷🇪', shortcode: 'reunion' },
  { ico: '🇷🇴', shortcode: 'romania' },
  { ico: '🇷🇸', shortcode: 'serbia' },
  { ico: '🇷🇺', shortcode: 'ru' },
  { ico: '🇷🇼', shortcode: 'rwanda' },
  { ico: '🇸🇦', shortcode: 'saudi_arabia' },
  { ico: '🇸🇧', shortcode: 'solomon_islands' },
  { ico: '🇸🇨', shortcode: 'seychelles' },
  { ico: '🇸🇩', shortcode: 'sudan' },
  { ico: '🇸🇪', shortcode: 'sweden' },
  { ico: '🇸🇬', shortcode: 'singapore' },
  { ico: '🇸🇭', shortcode: 'st_helena' },
  { ico: '🇸🇮', shortcode: 'slovenia' },
  { ico: '🇸🇯', shortcode: 'svalbard_jan_mayen' },
  { ico: '🇸🇰', shortcode: 'slovakia' },
  { ico: '🇸🇱', shortcode: 'sierra_leone' },
  { ico: '🇸🇲', shortcode: 'san_marino' },
  { ico: '🇸🇳', shortcode: 'senegal' },
  { ico: '🇸🇴', shortcode: 'somalia' },
  { ico: '🇸🇷', shortcode: 'suriname' },
  { ico: '🇸🇸', shortcode: 'south_sudan' },
  { ico: '🇸🇹', shortcode: 'sao_tome_principe' },
  { ico: '🇸🇻', shortcode: 'el_salvador' },
  { ico: '🇸🇽', shortcode: 'sint_maarten' },
  { ico: '🇸🇾', shortcode: 'syria' },
  { ico: '🇸🇿', shortcode: 'swaziland' },
  { ico: '🇹🇦', shortcode: 'tristan_da_cunha' },
  { ico: '🇹🇨', shortcode: 'turks_caicos_islands' },
  { ico: '🇹🇩', shortcode: 'chad' },
  { ico: '🇹🇫', shortcode: 'french_southern_territories' },
  { ico: '🇹🇬', shortcode: 'togo' },
  { ico: '🇹🇭', shortcode: 'thailand' },
  { ico: '🇹🇯', shortcode: 'tajikistan' },
  { ico: '🇹🇰', shortcode: 'tokelau' },
  { ico: '🇹🇱', shortcode: 'timor_leste' },
  { ico: '🇹🇲', shortcode: 'turkmenistan' },
  { ico: '🇹🇳', shortcode: 'tunisia' },
  { ico: '🇹🇴', shortcode: 'tonga' },
  { ico: '🇹🇷', shortcode: 'tr' },
  { ico: '🇹🇹', shortcode: 'trinidad_tobago' },
  { ico: '🇹🇻', shortcode: 'tuvalu' },
  { ico: '🇹🇼', shortcode: 'taiwan' },
  { ico: '🇹🇿', shortcode: 'tanzania' },
  { ico: '🇺🇦', shortcode: 'ukraine' },
  { ico: '🇺🇬', shortcode: 'uganda' },
  { ico: '🇺🇲', shortcode: 'us_outlying_islands' },
  { ico: '🇺🇳', shortcode: 'united_nations' },
  { ico: '🇺🇸', shortcode: 'us' },
  { ico: '🇺🇾', shortcode: 'uruguay' },
  { ico: '🇺🇿', shortcode: 'uzbekistan' },
  { ico: '🇻🇦', shortcode: 'vatican_city' },
  { ico: '🇻🇨', shortcode: 'st_vincent_grenadines' },
  { ico: '🇻🇪', shortcode: 'venezuela' },
  { ico: '🇻🇬', shortcode: 'british_virgin_islands' },
  { ico: '🇻🇮', shortcode: 'us_virgin_islands' },
  { ico: '🇻🇳', shortcode: 'vietnam' },
  { ico: '🇻🇺', shortcode: 'vanuatu' },
  { ico: '🇼🇫', shortcode: 'wallis_futuna' },
  { ico: '🇼🇸', shortcode: 'samoa' },
  { ico: '🇽🇰', shortcode: 'kosovo' },
  { ico: '🇾🇪', shortcode: 'yemen' },
  { ico: '🇾🇹', shortcode: 'mayotte' },
  { ico: '🇿🇦', shortcode: 'south_africa' },
  { ico: '🇿🇲', shortcode: 'zambia' },
  { ico: '🇿🇼', shortcode: 'zimbabwe' },
]

export const EMOJI_DATA = [
  {
    type: '常用',
    list: EMOJI_LIST_COMMON,
  },
  {
    type: '情绪',
    list: EMOJI_LIST_EMOTION,
  },
  {
    type: '动作',
    list: EMOJI_LIST_ACT,
  },
  {
    type: '自然',
    list: EMOJI_LIST_NATURE,
  },
  {
    type: '食物',
    list: EMOJI_LIST_FOOD,
  },
  {
    type: '地点',
    list: EMOJI_LIST_PLACE,
  },
  {
    type: '出行',
    list: EMOJI_LIST_TRAVEL,
  },
  {
    type: '时间/气候',
    list: EMOJI_LIST_TIME,
  },
  {
    type: '活动',
    list: EMOJI_LIST_ACTIVITY,
  },
  {
    type: '物品',
    list: EMOJI_LIST_OBJECT,
  },
  {
    type: '符号',
    list: EMOJI_LIST_SYMBOL,
  },
  {
    type: '缩写',
    list: ABBR_LIST_SYMBOL,
  },
]

export const EMOJI_LIST = [
  ...EMOJI_LIST_EMOTION,
  ...EMOJI_LIST_ACT,
  ...EMOJI_LIST_NATURE,
  ...EMOJI_LIST_FOOD,
  ...EMOJI_LIST_PLACE,
  ...EMOJI_LIST_TRAVEL,
  ...EMOJI_LIST_TIME,
  ...EMOJI_LIST_ACTIVITY,
  ...EMOJI_LIST_OBJECT,
  ...EMOJI_LIST_SYMBOL,
  ...ABBR_LIST_SYMBOL,
]

export const EMOJI_MAP = EMOJI_LIST.reduce((obj, item) => {
  obj[`:${item.shortcode}:`] = item.ico
  return obj
}, {})